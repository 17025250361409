(function (exports) { 
   exports.PT_PT_KICORE = {
 "catalog": { 
"Error 404": "Erro 404", 
 "Detalles Administrativos": "Detalhes administrativos", 
 "Detalles  M2M": "M2M Detalhes", 
 "Search": "Procurar", 
 "Reset": "Reiniciar", 
 "Instalaci\u00f3n/Pedido": "Instala\u00e7\u00e3o/pedido", 
 "Cliente": "Cliente", 
 "Estado": "Estado", 
 "Desactivada": "Desabilitado", 
 "Activada": "Ativado", 
 "Inactiva nueva": "Novo inativo", 
 "Lista para activaci\u00f3n": "Lista para ativa\u00e7\u00e3o", 
 "Desconocido": "Um estranho", 
 "Consumo diario": "Consumo di\u00e1rio", 
 "Consumo mensual": "Consumo mensal", 
 "Comienzo \u00faltima conexi\u00f3n": "Inicie a \u00faltima conex\u00e3o", 
 "Fin \u00faltima conexi\u00f3n": "Encerta Enconssimento Final", 
 "Compa\u00f1\u00eda de red": "Empresa de rede", 
 "C\u00f3digo de pa\u00eds": "C\u00f3digo do pa\u00eds", 
 "Tipo de red": "Tipo de rede", 
 "Tipo de tarifa": "Tipo de taxa", 
 "Zona 1": "Zona 1", 
 "Sin asignar": "N\u00e3o atribu\u00eddo", 
 "Cambiar ciclo vida": "Mudar o ciclo de vida", 
 "Por favor, seleccione una opci\u00f3n": "Por favor, selecione uma op\u00e7\u00e3o", 
 "Desactivar": "Desativar", 
 "Activar": "Ativar", 
 "Test": "Teste", 
 "Estado GSM": "Estado GSM", 
 "Estado GPRS": "Estado GPRS", 
 "Buscar": "Procurar", 
 "Borrar": "Excluir", 
 "Nombre": "Nome", 
 "Descripci\u00f3n": "Descri\u00e7\u00e3o", 
 "UUID placa": "Crach\u00e1 UUID", 
 "Estado instalaci\u00f3n": "Status da instala\u00e7\u00e3o", 
 "Desconectada": "Desconectado", 
 "Conectada": "Conectado", 
 "Deshabilitada": "Desabilitado", 
 "Habilitada": "Habilitado", 
 "Detalles": "Detalhes", 
 "No se pudieron actualizar los datos": "N\u00e3o foi poss\u00edvel atualizar os dados", 
 "Pools actualizados correctamente": "Piscinas atualizadas com sucesso", 
 "Tarjetas activas / tarjetas totales": "Cart\u00f5es ativos / total de cart\u00f5es", 
 "Consumo del pool / Pool total": "Consumo da piscina / Total da piscina", 
 "Gasto estimado del pool": "Despesa estimada do pool", 
 "\u00daltima actualizaci\u00f3n": "\u00daltima atualiza\u00e7\u00e3o", 
 "Refrescar": "Atualizar", 
 "Actualizar token de acceso": "Atualizar token de acesso", 
 "Por favor, complete este campo": "Por favor, preencha este campo", 
 "Enviar": "Enviar", 
 "Placa creada correctamente": "Placa criada com sucesso", 
 "Placa actualizada correctamente": "Plaque mise \u00e0 jour avec succ\u00e8s", 
 "MAC err\u00f3nea": "MAC errado", 
 "SSID err\u00f3neo": "SSID errado", 
 "Nuevo kiwi": "Novo kiwi", 
 "Informaci\u00f3n de kiwi": "Informa\u00e7\u00f5es sobre Kiwis", 
 "Guardar": "Manter", 
 "UUID err\u00f3neo": "UUID errado", 
 "Nuevo kibox": "Novo kibox", 
 "Informaci\u00f3n de kibox con UUID": "Informa\u00e7\u00f5es Kibox com UUID", 
 "Informaci\u00f3n de kibox": "Informa\u00e7\u00f5es Kibox", 
 "Habr\u00e1 mas de una placa con ese uuid.\u00bfEst\u00e1 seguro de esto?": "Haver\u00e1 mais de uma placa com esse uuid. Voc\u00ea tem certeza disso?", 
 "Modelo placa": "Modelo de placa", 
 "Seleccionar opci\u00f3n": "Selecione a op\u00e7\u00e3o", 
 "Tarda demasiado en encenderse": "Demora muito para ligar", 
 "Usuario": "Do utilizador", 
 "Nombre de usuario": "Nome de usu\u00e1rio", 
 "Rol": "Papel", 
 "Organizaci\u00f3n": "Organiza\u00e7\u00e3o", 
 "Apellidos": "Sobrenomes", 
 "Email": "E-mail", 
 "Complete los campos obligatorios": "Preencha os campos obrigat\u00f3rios", 
 "Las contrase\u00f1as tienen que ser iguales": "As senhas devem ser iguais", 
 "Nombre de Usuario": "Nome de usu\u00e1rio", 
 "Correo electr\u00f3nico": "Correio eletr\u00f4nico", 
 "Contrase\u00f1a": "Senha", 
 "Repetir contrase\u00f1a": "Repetir a senha", 
 "Selecciona organizaci\u00f3n": "Selecione a organiza\u00e7\u00e3o", 
 "Nivel": "N\u00edvel", 
 "Selecciona un rol": "Selecione uma fun\u00e7\u00e3o", 
 "Superadministrador": "Super administrador", 
 "Administrador": "Administrador", 
 "Operador": "Operador", 
 "Visitante": "Visitante", 
 "Activo": "Ativo", 
 "Crear cuenta": "Criar uma conta", 
 "Inicio": "Come\u00e7ar", 
 "Nuevo equipo": "Novo time", 
 "Stock": "Estoque", 
 "Asignaciones": "Atribui\u00e7\u00f5es", 
 "Lista equipos": "Lista de equipamentos", 
 "Instalaciones": "Instala\u00e7\u00f5es", 
 "Listado M2M": "Listado M2M", 
 "Listado Pools": "Listado Pools", 
 "Soporte": "M\u00e9dio", 
 "Crear Usuario": "Criar usu\u00e1rio", 
 "Lista Usuarios": "Lista de usu\u00e1rios", 
 "error": "erro", 
 "Informaci\u00f3n de tarjeta M2M con icc": "Informa\u00e7\u00f5es do cart\u00e3o M2M com ICC", 
 "Alias": "Pseud\u00f4nimo", 
 "Life Cycle Status": "Status do ciclo de vida", 
 "Pais": "Pa\u00eds", 
 "Plan": "Plano", 
 "Estado Actual": "Estado atual", 
 "\u00daltimo evento": "\u00daltimo evento", 
 "Tecno.Conexi\u00f3n": "Techno.Connection", 
 "\u00daltima Conexi\u00f3n": "\u00daltima conex\u00e3o", 
 "Complete los campos": "Preencha os campos", 
 "Inicio de sesi\u00f3n exitoso": "Login bem-sucedido", 
 "Introduzca un nombre de usuario": "Digite um nome de usu\u00e1rio", 
 "Introduzca una contrase\u00f1a": "Insira uma senha", 
 "Iniciar sesi\u00f3n": "Iniciar sess\u00e3o", 
 "Eliminar": "Eliminar", 
 "Editar": "Editar", 
 "Asignar": "Atribuir", 
 "N\u00famero de serie err\u00f3neo": "N\u00famero de s\u00e9rie errado", 
 "Nuevo KiWi": "Novo KiWi", 
 "Pedido": "Ordem", 
 "N serie": "S\u00e9rie N", 
 "Fecha fabricaci\u00f3n": "Data de fabrica\u00e7\u00e3o", 
 "Estado de fabricaci\u00f3n": "Status de fabrica\u00e7\u00e3o", 
 "KiWi terminado": "KiWi finalizado", 
 "No se ha podido terminar": "N\u00e3o consegui terminar", 
 "Terminar": "Terminar", 
 "Version hardware": "Vers\u00e3o de hardware", 
 "Modelo": "Modelo", 
 "Tipo USB": "Tipo USB", 
 "Instalacion": "Instala\u00e7\u00e3o", 
 "Cliente kiconex": "Cliente kiconex", 
 "Cliente final": "Cliente final", 
 "N\u00famero de serie": "N\u00famero de s\u00e9rie", 
 "kiconex plan": "Plano kiconex", 
 "Nº dispositivos": "Nº de dispositivos", 
 "Cuadro": "Gr\u00e1fico", 
 "Placa terminada": "Placa acabada", 
 "Opcionales": "Opcionales", 
 "Notas": "Notas", 
 "Crear nuevo dispositivo": "Criar novo dispositivo", 
 "Bienvenido a kicore ¡la plataforma de gesti\u00f3n de Kiconex!": "Bem-vindo ao kicore, a plataforma de gest\u00e3o da Kiconex!", 
 "Ver en kiconex": "Veja em kiconex", 
 "ICC err\u00f3neo": "ICC errado", 
 "Nombre instalaci\u00f3n": "Nome da instala\u00e7\u00e3o", 
 "Nº serie": "N\u00famero de s\u00e9rie", 
 "Versi\u00f3n hardware": "Vers\u00e3o de hardware", 
 "Ninguno": "Nenhum", 
 "Chino": "Chin\u00eas", 
 "Basic": "B\u00e1sico", 
 "Professional": "Profissional", 
 "Premium": "Pr\u00eamio", 
 "Versi\u00f3n OEM": "Vers\u00e3o OEM", 
 "No": "N\u00e3o", 
 "S\u00ed": "Sim", 
 "Cable": "Arame", 
 "Fabricado": "Fabricado", 
 "Asignado": "Atribu\u00eddo", 
 "Terminado": "Finalizado", 
 "Eliminado": "Removido", 
 "Volver": "Retornar", 
 "Plan kiconex": "Plano kiconex", 
 "¡MAC err\u00f3nea!": "MAC errado!", 
 "¡SSID err\u00f3neo!": "SSID errado!", 
 "¡UUID err\u00f3neo!": "UUID errado!", 
 "¡ICC err\u00f3neo!": "ICC errado!", 
 "¡N\u00famero de serie err\u00f3neo!": "N\u00famero de s\u00e9rie errado!", 
 "Grupo comercial": "Grupo comercial", 
 "Habilitaci\u00f3n": "Qualifica\u00e7\u00e3o", 
 "F\u00e1brica": "F\u00e1brica", 
 "Administraci\u00f3n": "Administra\u00e7\u00e3o", 
 "Gesti\u00f3n": "Gerenciamento", 
 "Usuarios": "Usu\u00e1rios", 
 "Lista kibox": "Lista kibox", 
 "Lista kiwi": "Lista kiwi", 
 "Kibox asignados": "Kiboxs atribu\u00eddos", 
 "Kiwis asignados": "Kiwis atribu\u00eddos", 
 "No se conecta al WiFi": "N\u00e3o conecta ao Wi-Fi", 
 "Este pedido debe salir con una pegatina extra": "Este pedido deve vir com um adesivo extra", 
 "Cambiar estado de la SIM": "Alterar o estado do SIM", 
 "Estado de la SIM": "Estado do chip", 
 "Fruter\u00eda fulanito": "Verdureiro fulano", 
 "Token actualizado correctamente": "Token atualizado com sucesso", 
 "Usuario creado correctamente": "Usu\u00e1rio criado com sucesso", 
 "No hay respuesta del servidor": "Nenhuma resposta do servidor", 
 "Error": "Erro", 
 "Error al cerrar sesi\u00f3n": "Erro ao sair", 
 "Seleccionar idioma": "Selecione o idioma", 
 "Espa\u00f1ol": "Espanhol", 
 "Ingl\u00e9s": "Ingl\u00eas", 
 "Franc\u00e9s": "Franc\u00eas", 
 "Alem\u00e1n": "Alem\u00e3o", 
 "Portugu\u00e9s": "Portugu\u00eas", 
 "Italiano": "Italiano", 
 "UUID": "UUID", 
 "Nombre comercial": "Nome comercial", 
 "Pedido comercial": "Ordem comercial", 
 "N\u00famero de serie comercial": "N\u00famero de s\u00e9rie comercial", 
 "Modelo del equipo": "Modelo do equipamento", 
 "Estado Comercial": "Status Comercial", 
 "Fecha Conexi\u00f3n": "Conex\u00e3o de data", 
 "Subscripci\u00f3n": "Inscri\u00e7\u00e3o", 
 "Comercial": "Comercial", 
 "General": "Em geral", 
 "Placa borrada correctamente": "Placa apagada com sucesso", 
 "Sistema": "Sistema", 
 "Privilegios": "Privil\u00e9gios", 
 "Roles": "Fun\u00e7\u00f5es", 
 "Usuario borrado correctamente": "Usu\u00e1rio exclu\u00eddo com sucesso", 
 "Cambiar contrase\u00f1a": "Alterar a senha", 
 "Columnas": "Colunas", 
 "Sin informaci\u00f3n": "Sem informa\u00e7\u00e3o", 
 "Cerrar": "Fechar", 
 "Cambiar": "Mudar", 
 "Por favor, seleccione una opcion": "Por favor selecione uma op\u00e7\u00e3o", 
 "¡Las contrase\u00f1as deben coincidir!": "¡As senhas devem corresponder!", 
 "La contrase\u00f1a es un campo obligatorio": "Senha \u00e9 um campo obrigat\u00f3rio", 
 "success": "sucesso", 
 "Contrase\u00f1a actualizada correctamente": "Senha atualizada com sucesso", 
 "Las contrase\u00f1as deben coincidir": "As senhas devem corresponder", 
 "Cambio de contrase\u00f1a usuario": "Altera\u00e7\u00e3o de senha do usu\u00e1rio", 
 "Debe seleccionar un rol": "Voc\u00ea deve selecionar uma fun\u00e7\u00e3o", 
 "Rol creado correctamente": "Fun\u00e7\u00e3o criada com sucesso", 
 "Nuevo Rol": "Novo papel", 
 "Informaci\u00f3n de Rol": "Informa\u00e7\u00f5es da fun\u00e7\u00e3o", 
 "Rol borrado correctamente": "Fun\u00e7\u00e3o exclu\u00edda com sucesso", 
 "Rol administrador": "Fun\u00e7\u00e3o de administrador", 
 "Sincronizar": "Sincronizar", 
 "¡Sincronizar datos!": "¡sincronizar dados!", 
 "Nombre, Organizaci\u00f3n y subscripci\u00f3n van a ser modificados por el valor que tengan en la plataforma Kiconex \u00bfEst\u00e1 seguro de esto?": "Nome, Organiza\u00e7\u00e3o e subscri\u00e7\u00e3o ser\u00e3o modificados pelo valor que t\u00eam na plataforma Kiconex \u00bfTem a certeza disso?", 
 "Usuario guardado correctamente": "Usu\u00e1rio salvo com sucesso", 
 "Nuevo usuario": "Novo usu\u00e1rio", 
 "Editando usuario": "Usu\u00e1rio de edi\u00e7\u00e3o", 
 "Software": "Software", 
 "Crear": "Criar", 
 "Ver": "Ver", 
 "Finalizar": "Terminar", 
 "Editar comercial": "Editar comercial", 
 "Gesti\u00f3n de placas de f\u00e1brica": "Gest\u00e3o de placas de f\u00e1brica", 
 "Asignaciones de f\u00e1brica": "Atribui\u00e7\u00f5es de f\u00e1brica", 
 "Asignaci\u00f3n de placas de f\u00e1brica": "Atribui\u00e7\u00e3o de placas de f\u00e1brica", 
 "Gesti\u00f3n de placas de administraci\u00f3n": "Diretoria de Administra\u00e7\u00e3o", 
 "mykiconex": "mykiconex", 
 "Gesti\u00f3n con plataforma mykiconex": "Gest\u00e3o com plataforma mykiconex", 
 "M2M": "M2M", 
 "Ver M2M": "Ver M2M", 
 "Ver estado": "Ver status", 
 "Ver listado pools": "Ver lista pools", 
 "Refrescar pools": "Atualizar pools", 
 "Actualizar token": "Token de atualiza\u00e7\u00e3o", 
 "VPN": "VPN", 
 "Chat": "Chat", 
 "Ver privilegios": "Ver privil\u00e9gios", 
 "Editar privilegios": "Editar privil\u00e9gios", 
 "Gesti\u00f3n de sistema": "Administra\u00e7\u00e3o de sistema", 
 "Gesti\u00f3n de usuarios": "Gerenciamento de usu\u00e1rios", 
 "Ver acceso a chat": "Ver acesso ao chat", 
 "Ver acceso a soporte": "Ver acesso ao suporte", 
 "Ver acceso a VPN": "Ver Acesso VPN", 
 "Gesti\u00f3n de tarjetas M2M": "Gest\u00e3o de cart\u00f5es M2M", 
 "Autorizaci\u00f3n": "Autoriza\u00e7\u00e3o", 
 "Fecha primera contrataci\u00f3n": "Primeira data de contrata\u00e7\u00e3o", 
 "Fecha \u00faltima renovaci\u00f3n": "\u00daltima data de renova\u00e7\u00e3o", 
 "Intervalo de renovaci\u00f3n (a\u00f1os)": "Intervalo de renova\u00e7\u00e3o (anos)", 
 "Fecha primera conexi\u00f3n": "Primeira data de conex\u00e3o", 
 "Privilegios guardados correctamente": "Privil\u00e9gios salvos corretamente", 
 "Rol actualizado correctamente": "Fun\u00e7\u00e3o atualizada corretamente", 
 "N\u00famero Pedido": "N\u00famero de pedido", 
 "Asunto": "Assunto", 
 "Asignado a": "Atribu\u00eddo a", 
 "Fecha de Creaci\u00f3n": "Data de cria\u00e7\u00e3o", 
 "Pendiente": "Pendente", 
 "Cambiar Estado": "Alterar status", 
 "Estado del Pedido actualizado correctamente": "Encomendar estado atualizado corretamente", 
 "Cliente Final": "Cliente final", 
 "Referencia Presupuesto": "Refer\u00eancia de or\u00e7amento", 
 "Precio Total": "Pre\u00e7o total", 
 "Ver en CRM": "Veja no CRM", 
 "N\u00famero Presupuesto": "N\u00famero do or\u00e7amento", 
 "En Proceso": "Em processo", 
 "Anulado": "Cancelado", 
 "Cambiar estado del pedido": "Alterar o status do pedido", 
 "Pedidos": "Ordens", 
 "Gesti\u00f3n de pedidos": "Gest\u00e3o de pedidos", 
 "Error en la operaci\u00f3n": "Erro de opera\u00e7\u00e3o", 
 "UUID de placa ya en uso": "Placa uuid j\u00e1 em uso", 
 "Nueva Placa Guardada": "Nova placa salva", 
 "Placa actualizada": "Placa atualizada", 
 "Placa borrada": "Placa exclu\u00edda", 
 "Placas actualizadas": "Placas atualizadas", 
 "SSID de kiwi ya en uso": "Kiwi ssid j\u00e1 em uso", 
 "Nuevo KiWi Guardado": "Novo kiwi salvo", 
 "KiWi actualizado": "Kiwi atualizado", 
 "KiWi borrado": "Kiwi exclu\u00eddo", 
 "Token actualizado": "Token atualizado", 
 "Nombre de rol ya en uso": "Nome do papel j\u00e1 em uso", 
 "Rol actualizado": "Fun\u00e7\u00e3o atualizada", 
 "No puede eliminar un rol con dependencias": "N\u00e3o pode eliminar um papel com depend\u00eancias", 
 "La clave ha expirado.": "A chave expirou.", 
 "Usuario no encontrado.": "Usu\u00e1rio n\u00e3o encontrado.", 
 "Las contrase\u00f1as son obligatorias.": "As senhas s\u00e3o obrigat\u00f3rias.", 
 "Las contrase\u00f1as no coinciden.": "As senhas n\u00e3o correspondem.", 
 "Nombre de usuario ya en uso": "Nome de usu\u00e1rio j\u00e1 em uso", 
 "Error del servidor.": "Erro de servidor.", 
 "El usuario no ha sido activado.": "O usu\u00e1rio n\u00e3o foi ativado.", 
 "Inicio de sesi\u00f3n con \u00e9xito": "Login bem-sucedido", 
 "Contrase\u00f1a incorrecta": "Senha incorreta", 
 "Fecha Renovaci\u00f3n": "Data de renova\u00e7\u00e3o", 
 "Tiempo Restante": "Tempo Restante", 
 " meses restantes": " meses restantes", 
 "Pedidos a expirar": "Ordens para expirar", 
 "Descargar Informe": "Download relat\u00f3rio", 
 "Renovaciones": "Reformas", 
 "Pr\u00f3ximo mes": "Pr\u00f3ximo m\u00eas", 
 "Pr\u00f3ximos 3 meses": "Pr\u00f3ximos 3 meses", 
 "Pr\u00f3ximos 6 meses": "Pr\u00f3ximos 6 meses", 
 "Pr\u00f3ximo a\u00f1o": "Pr\u00f3ximo ano", 
 "Ver renovaciones": "Veja reformas", 
 "Caducada": "Expirado", 
 " d\u00edas restantes": "  dias restantes", 
 "Aceptar": "Aceitar", 
 "Reiniciar": "Reiniciar", 
 "Sin organizaci\u00f3n": "Nenhuma organiza\u00e7\u00e3o", 
 "Ver pools": "Veja pools", 
 "Alarmas": "Alarmes", 
 "Alarmas M2M": "Alarmes M2M", 
 "Sin alertas": "Sem alertas", 
 "Advertencia": "Aviso", 
 "Critica": "Critica", 
 "Renovaciones expiradas": "Reformas expiradas", 
 "Pedidos pendientes": "Ordens pendentes", 
 "Tarjetas desactivadas desde hace mas de 12 meses": "Cart\u00f5es desativados por mais de 12 meses", 
 "Ver m\u00e1s": "Ver mais", 
 "Creaci\u00f3n": "Cria\u00e7\u00e3o", 
 "Actualizaci\u00f3n": "Atualizar", 
 "Borrado": "Apagado", 
 "Cambio de estado": "Mudan\u00e7a de status", 
 "Cambio de contrase\u00f1a": "Mudan\u00e7a de senha", 
 "Sincronizaci\u00f3n": "Sincroniza\u00e7\u00e3o", 
 "Sin acci\u00f3n": "Sem a\u00e7\u00e3o", 
 "Fecha": "Data", 
 "Acci\u00f3n": "A\u00e7\u00e3o", 
 "Tipo de objeto": "Tipo de objeto", 
 "Identificador": "Identificador", 
 "Placa": "Placa", 
 "Hace 3 horas": "3 horas atr\u00e1s", 
 "Hace 1 d\u00eda": "1 dia atr\u00e1s", 
 "Hace 3 d\u00edas": "H\u00e1 3 dias", 
 "Hace 1 semana": "1 semana atr\u00e1s", 
 "Actividad": "Atividade", 
 "Listado": "Lista", 
 "Ver actividad": "Veja Atividade", 
 "Privilegio": "Privil\u00e9gio", 
 "No es posible personificar desde un personificado.": "N\u00e3o \u00e9 poss\u00edvel personificar de um personificado.", 
 "Personificaci\u00f3n": "Personifica\u00e7\u00e3o", 
 "Personificando al usuario": "Personificando o usu\u00e1rio", 
 "Volver a la sesi\u00f3n": "V\u00e1 para a sess\u00e3o", 
 "Personificar": "Personificar", 
 "No es posible personificarse a si mismo.": "N\u00e3o \u00e9 poss\u00edvel se personificar.", 
 "Comienzo Personificaci\u00f3n": "Come\u00e7ar", 
 "Fin Personificaci\u00f3n": "Personifica\u00e7\u00e3o final", 
 "Ajustes": "Configura\u00e7\u00f5es", 
 "Actualizar listado": "Lista de atualiza\u00e7\u00f5es", 
 "Refrescar pedidos": "Atualizar ordens", 
 "Refrescar ordenes de trabajo": "Atualizar ordens de trabalho", 
 "de": "de", 
 "filtrados de": "filtrado de", 
 "Actualizar listado de organizaciones": "Atualizar lista de organiza\u00e7\u00f5es", 
 "Actualizar nombre, plan y organizaci\u00f3n de kibox": "Atualize o nome, plano e organiza\u00e7\u00e3o de Kibox", 
 "Actualizar listado de pedidos": "Lista de pedidos de atualiza\u00e7\u00e3o", 
 "Actualizar listado de ordenes de trabajo": "Lista de ordens de trabalho de atualiza\u00e7\u00e3o", 
 "Actualizaci\u00f3n de listados": "Atualiza\u00e7\u00e3o da lista", 
 "Actualizado con exito": "Atualizado com sucesso", 
 "Activa": "Ativo", 
 "Sin intervalo": "Sem intervalo", 
 "Mostrar todo": "Mostre tudo", 
 "Estado de suscripci\u00f3n no compatible con el intervalo seleccionado": "Status de assinatura n\u00e3o compat\u00edvel com o intervalo selecionado", 
 "Estado del plan": "Status do plano", 
 "Caducado": "Tempo esgotado", 
 "Inactivo": "Parado", 
 "Cancelado": "Cancelado", 
 "No procede": "N\u00e3o procede", 
 "¡Orden de trabajo erronea!": "Ordem de servi\u00e7o err\u00f4nea!", 
 "Rellene el formulario correctamente": "Preencha o formul\u00e1rio corretamente", 
 "Orden de trabajo": "Ordem de trabalho", 
 "Su sesi\u00f3n va a ser cerrada": "Sua sess\u00e3o ser\u00e1 fechada", 
 "Su sesi\u00f3n se cerrar\u00e1 para aplicar los cambios": "Voc\u00ea ser\u00e1 desconectado para aplicar as altera\u00e7\u00f5es", 
 "Inicie sesi\u00f3n de nuevo": "Fa\u00e7a login novamente", 
 "Posee una versi\u00f3n no compatible": "Tem uma vers\u00e3o n\u00e3o compat\u00edvel", 
 "Consumo": "Consumo", 
 "Consumo de la SIM": "Consumo do SIM", 
 "Mensual": "Por m\u00eas", 
 "Diario": "Di\u00e1rio", 
 "UUID ya en uso": "UUID j\u00e1 em uso", 
 "VPN creada correctamente": "VPN criada com sucesso", 
 "VPN actualizada correctamente": "VPN atualizada com sucesso", 
 "Contrase\u00f1a copiada al portapapeles": "Senha copiada para a \u00e1rea de transfer\u00eancia", 
 "Fallo al copiar la contrase\u00f1a": "Falha ao copiar a senha", 
 "Nueva conexi\u00f3n VPN": "Nova conex\u00e3o VPN", 
 "Regenerar contrase\u00f1a": "Gerar senha novamente", 
 "Contrase\u00f1a VPN": "Senha VPN", 
 "Copie la contrase\u00f1a antes de guardar, no volver\u00e1 a ser visible": "Copie a senha antes de salvar, ela n\u00e3o ficar\u00e1 vis\u00edvel novamente", 
 "VPN (antigua)": "VPN (antigo)", 
 "Ver acceso a VPN (antigua)": "Ver acesso VPN (antigo)", 
 "Ver VPN (antigua)": "Ver VPN (antigo)", 
 "Ver VPN": "Ver VPN", 
 "Crear VPN": "Criar VPN", 
 "Editar VPN": "Editar VPN", 
 "Ver informaci\u00f3n": "Ver informa\u00e7\u00f5es", 
 "Informaci\u00f3n placas": "Informa\u00e7\u00f5es da placa", 
 "Modelo de hardware": "Modelo de hardware", 
 "Fecha de compilaci\u00f3n": "Data de constru\u00e7\u00e3o", 
 "Versi\u00f3n de Daemon": "Vers\u00e3o do daemon", 
 "Fecha de actualizaci\u00f3n": "Data de atualiza\u00e7\u00e3o", 
 "Actualizado": "Atualizada", 
 "No actualizado": "N\u00e3o atualizado", 
 "Descargar fichero de configuraci\u00f3n": "Baixar arquivo de configura\u00e7\u00e3o", 
 "Nunca": "Nunca", 
 "Dispositivo": "Dispositivo", 
 },
  "plural": "(n != 1)"
  };
  })(this);