(function (exports) { 
   exports.FR_FR_KICORE = {
 "catalog": { 
"Error 404": "Erreur 404", 
 "Detalles Administrativos": "D\u00e9tails administratifs", 
 "Detalles  M2M": "D\u00e9tails M2M", 
 "Search": "Rechercher", 
 "Reset": "R\u00e9initialisation", 
 "Instalaci\u00f3n/Pedido": "Appareil/Commande", 
 "Cliente": "Client", 
 "Estado": "\u00c9tat", 
 "Desactivada": "D\u00e9sactiv\u00e9", 
 "Activada": "Activ\u00e9", 
 "Inactiva nueva": "Inactif nouveau", 
 "Lista para activaci\u00f3n": "Pr\u00eat pour l'activation", 
 "Desconocido": "Inconnue", 
 "Consumo diario": "Consommation quotidienne", 
 "Consumo mensual": "Consommation mensuelle", 
 "Comienzo \u00faltima conexi\u00f3n": "D\u00e9marrer la derni\u00e8re connexion", 
 "Fin \u00faltima conexi\u00f3n": "Fin de la derni\u00e8re connexion", 
 "Compa\u00f1\u00eda de red": "Entreprise de r\u00e9seau", 
 "C\u00f3digo de pa\u00eds": "Code postal", 
 "Tipo de red": "Type de r\u00e9seau", 
 "Tipo de tarifa": "Type de taux", 
 "Zona 1": "Zone 1", 
 "Sin asignar": "Non attribu\u00e9", 
 "Cambiar ciclo vida": "Changer la dur\u00e9e de vie du cycle", 
 "Por favor, seleccione una opci\u00f3n": "Veuillez s\u00e9lectionner une option", 
 "Desactivar": "D\u00e9sactiver", 
 "Activar": "Activer", 
 "Test": "Test", 
 "Estado GSM": "Statut GSM", 
 "Estado GPRS": "Statut GPRS", 
 "Buscar": "Chercher", 
 "Borrar": "Effacer", 
 "Nombre": "Nom", 
 "Descripci\u00f3n": "La description", 
 "UUID placa": "UUID plaque", 
 "Estado instalaci\u00f3n": "\u00c9tat de l'installation", 
 "Desconectada": "D\u00e9branch\u00e9", 
 "Conectada": "Connect\u00e9", 
 "Deshabilitada": "D\u00e9sactiv\u00e9", 
 "Habilitada": "Activ\u00e9", 
 "Detalles": "Des d\u00e9tails", 
 "No se pudieron actualizar los datos": "\u00c9chec de la mise \u00e0 jour des donn\u00e9es", 
 "Pools actualizados correctamente": "Pools mis \u00e0 jour avec succ\u00e8s", 
 "Tarjetas activas / tarjetas totales": "Cartes actives / Nombre total de cartes", 
 "Consumo del pool / Pool total": "Consommation piscine / Piscine totale", 
 "Gasto estimado del pool": "Estimation des d\u00e9penses du pool", 
 "\u00daltima actualizaci\u00f3n": "Derni\u00e8re mise \u00e0 jour", 
 "Refrescar": "Rafra\u00eechir", 
 "Actualizar token de acceso": "Actualiser le jeton d'acc\u00e8s", 
 "Por favor, complete este campo": "Veuillez remplir ce champ", 
 "Enviar": "Envoyer", 
 "Placa creada correctamente": "Plaque cr\u00e9\u00e9e avec succ\u00e8s", 
 "Placa actualizada correctamente": "Plaque mise \u00e0 jour avec succ\u00e8s", 
 "MAC err\u00f3nea": "Mauvais MAC", 
 "SSID err\u00f3neo": "Mauvais SSID", 
 "Nuevo kiwi": "Nouveau kiwi", 
 "Informaci\u00f3n de kiwi": "Infos sur les kiwis", 
 "Guardar": "Sauvegarder", 
 "UUID err\u00f3neo": "Mauvais UUID", 
 "Nuevo kibox": "Nouveau kibox", 
 "Informaci\u00f3n de kibox con UUID": "Informations Kibox avec UUID", 
 "Informaci\u00f3n de kibox": "Informations Kibox", 
 "Habr\u00e1 mas de una placa con ese uuid.\u00bfEst\u00e1 seguro de esto?": "Il y aura plus d'une plaque avec cet uuid. \u00cates-vous s\u00fbr de cela?", 
 "Modelo placa": "Mod\u00e8le de plaque", 
 "Seleccionar opci\u00f3n": "S\u00e9lectionner l'option", 
 "Tarda demasiado en encenderse": "Prend trop de temps \u00e0 s'allumer", 
 "Usuario": "Utilisateur", 
 "Nombre de usuario": "Nom d'utilisateur", 
 "Rol": "R\u00f4le", 
 "Organizaci\u00f3n": "Organisation", 
 "Apellidos": "Le nom", 
 "Email": "Email", 
 "Complete los campos obligatorios": "Remplissez les champs obligatoires", 
 "Las contrase\u00f1as tienen que ser iguales": "Les mots de passe doivent \u00eatre identiques", 
 "Nombre de Usuario": "Nom d'utilisateur", 
 "Correo electr\u00f3nico": "Courrier \u00e9lectronique", 
 "Contrase\u00f1a": "Mot de passe", 
 "Repetir contrase\u00f1a": "R\u00e9p\u00e9ter le mot de passe", 
 "Selecciona organizaci\u00f3n": "S\u00e9lectionnez l'organisation", 
 "Nivel": "Niveau", 
 "Selecciona un rol": "S\u00e9lectionnez un r\u00f4le", 
 "Superadministrador": "Super administrateur", 
 "Administrador": "Administrateur", 
 "Operador": "Op\u00e9rateur", 
 "Visitante": "Visiteur", 
 "Activo": "Actif", 
 "Crear cuenta": "Cr\u00e9er un compte", 
 "Inicio": "D\u00e9but", 
 "Nuevo equipo": "Nouvelle \u00e9quipe", 
 "Stock": "Stocker", 
 "Asignaciones": "Affectations", 
 "Lista equipos": "Liste des appareils", 
 "Instalaciones": "L' appareil", 
 "Listado M2M": "R\u00e9f\u00e9rencement M2M", 
 "Listado Pools": "Liste de pool", 
 "Soporte": "Soutien", 
 "Crear Usuario": "Cr\u00e9er un utilisateur", 
 "Lista Usuarios": "Liste d'utilisateur", 
 "error": "Erreur", 
 "Informaci\u00f3n de tarjeta M2M con icc": "Informations sur la carte M2M avec ICC", 
 "Alias": "Alias", 
 "Life Cycle Status": "\u00c9tat du cycle de vie", 
 "Pais": "Pays", 
 "Plan": "Plan", 
 "Estado Actual": "\u00c9tat actuel", 
 "\u00daltimo evento": "Dernier \u00e9v\u00e9nement", 
 "Tecno.Conexi\u00f3n": "Techno.Connexion", 
 "\u00daltima Conexi\u00f3n": "Derni\u00e8re connexion", 
 "Complete los campos": "Remplissez les champs", 
 "Inicio de sesi\u00f3n exitoso": "Connexion r\u00e9ussie", 
 "Introduzca un nombre de usuario": "Entrez un nom d'utilisateur", 
 "Introduzca una contrase\u00f1a": "Entrer un mot de passe", 
 "Iniciar sesi\u00f3n": "Commencer la session", 
 "Eliminar": "\u00c9liminer", 
 "Editar": "Modifier", 
 "Asignar": "Attribuer", 
 "N\u00famero de serie err\u00f3neo": "Mauvais num\u00e9ro de s\u00e9rie", 
 "Nuevo KiWi": "Nouveau kiwi", 
 "Pedido": "Commande", 
 "N serie": "S\u00e9rie N", 
 "Fecha fabricaci\u00f3n": "Date de fabrication", 
 "Estado de fabricaci\u00f3n": "Statut de fabrication", 
 "KiWi terminado": "Kiwi fini", 
 "No se ha podido terminar": "N'a pas pu finir", 
 "Terminar": "Finir", 
 "Version hardware": "Version mat\u00e9rielle", 
 "Modelo": "Mod\u00e8le", 
 "Tipo USB": "Type USB", 
 "Instalacion": "L' appareil", 
 "Cliente kiconex": "Client kiconex", 
 "Cliente final": "Client final", 
 "N\u00famero de serie": "Num\u00e9ro de s\u00e9rie", 
 "kiconex plan": "Forfait Kiconex", 
 "Nº dispositivos": "Nombre d'appareils", 
 "Cuadro": "Cadre", 
 "Placa terminada": "Assiette finie", 
 "Opcionales": "Optionnel", 
 "Notas": "Notes", 
 "Crear nuevo dispositivo": "Cr\u00e9er un nouvel appareil", 
 "Bienvenido a kicore ¡la plataforma de gesti\u00f3n de Kiconex!": "Bienvenue sur kicore, la plateforme de gestion de Kiconex !", 
 "Ver en kiconex": "Voir dans kiconex", 
 "ICC err\u00f3neo": "ICC mauvais", 
 "Nombre instalaci\u00f3n": "Nom de l'appareil", 
 "Nº serie": "Num\u00e9ro de s\u00e9rie", 
 "Versi\u00f3n hardware": "Version mat\u00e9rielle", 
 "Ninguno": "Rien", 
 "Chino": "C", 
 "Basic": "Basic", 
 "Professional": "Proffesional", 
 "Premium": "Premium", 
 "Versi\u00f3n OEM": "Versi\u00f3n OEM", 
 "No": "Ne pas", 
 "S\u00ed": "Oui", 
 "Cable": "C\u00e2ble", 
 "Fabricado": "Fabriqu\u00e9", 
 "Asignado": "Attribu\u00e9", 
 "Terminado": "Achev\u00e9e", 
 "Eliminado": "Supprim\u00e9", 
 "Volver": "Revenir", 
 "Plan kiconex": "Kiconex plan", 
 "¡MAC err\u00f3nea!": "Mauvais MAC!", 
 "¡SSID err\u00f3neo!": "Mauvais SSID!", 
 "¡UUID err\u00f3neo!": "Mauvais UUID!", 
 "¡ICC err\u00f3neo!": "Mauvais ICC!", 
 "¡N\u00famero de serie err\u00f3neo!": "Mauvais num\u00e9ro de s\u00e9rie!", 
 "Grupo comercial": "Groupe commercial", 
 "Habilitaci\u00f3n": "Activation", 
 "F\u00e1brica": "Usine", 
 "Administraci\u00f3n": "Administration", 
 "Gesti\u00f3n": "Gestion", 
 "Usuarios": "Utilisateurs", 
 "Lista kibox": "Kibox liste", 
 "Lista kiwi": "Kiwi liste", 
 "Kibox asignados": "Kibox attribu\u00e9es", 
 "Kiwis asignados": "Kiwi attribu\u00e9", 
 "No se conecta al WiFi": "Ne se connecte pas au WiFi", 
 "Este pedido debe salir con una pegatina extra": "Cette commande doit sortir avec un autocollant suppl\u00e9mentaire", 
 "Cambiar estado de la SIM": "Changer l'\u00e9tat de la carte SIM", 
 "Estado de la SIM": "\u00c9tat de fabrication", 
 "Fruter\u00eda fulanito": "Exemple de magasin de fruits", 
 "Token actualizado correctamente": "Jeton mis \u00e0 jour avec succ\u00e8s", 
 "Usuario creado correctamente": "Utilisateur cr\u00e9\u00e9 avec succ\u00e8s", 
 "No hay respuesta del servidor": "Il n'y a pas de r\u00e9ponse du serveur", 
 "Error": "Erreur", 
 "Error al cerrar sesi\u00f3n": "\u00c9chec de la d\u00e9connexion", 
 "Seleccionar idioma": "S\u00e9lectionner une langue", 
 "Espa\u00f1ol": "Espagnol", 
 "Ingl\u00e9s": "Anglais", 
 "Franc\u00e9s": "Fran\u00e7ais", 
 "Alem\u00e1n": "Allemand", 
 "UUID": "UUID", 
 "Nombre comercial": "Nom commercial", 
 "Pedido comercial": "Commande commerciale", 
 "N\u00famero de serie comercial": "Num\u00e9ro de s\u00e9rie commerciale", 
 "Modelo del equipo": "Mod\u00e8le d'\u00e9quipement", 
 "Estado Comercial": "Statut Commercial", 
 "Fecha Conexi\u00f3n": "Date de connexion", 
 "Subscripci\u00f3n": "abonnement", 
 "Comercial": "Commercial", 
 "General": "G\u00e9n\u00e9ral", 
 "Placa borrada correctamente": "Plaque effac\u00e9e avec succ\u00e8s", 
 "Sistema": "Syst\u00e8me", 
 "Privilegios": "Privil\u00e8ges", 
 "Roles": "Les r\u00f4les", 
 "Usuario borrado correctamente": "Utilisateur supprim\u00e9 avec succ\u00e8s", 
 "Cambiar contrase\u00f1a": "Changer le mot de passe", 
 "Columnas": "Colonnes", 
 "Sin informaci\u00f3n": "Aucune information", 
 "Cerrar": "Fermer", 
 "Cambiar": "Changer", 
 "Por favor, seleccione una opcion": "Veuillez s\u00e9lectionner une option", 
 "¡Las contrase\u00f1as deben coincidir!": "¡Les mots de passe doivent correspondre!", 
 "La contrase\u00f1a es un campo obligatorio": "Mot de passe est un champ obligatoire", 
 "success": "succ\u00e8s", 
 "Contrase\u00f1a actualizada correctamente": "Mot de passe mis \u00e0 jour avec succ\u00e8s", 
 "Las contrase\u00f1as deben coincidir": "Les mots de passe doivent correspondre", 
 "Cambio de contrase\u00f1a usuario": "Changement de mot de passe utilisateur", 
 "Debe seleccionar un rol": "Vous devez s\u00e9lectionner un r\u00f4le", 
 "Rol creado correctamente": "R\u00f4le cr\u00e9\u00e9 avec succ\u00e8s", 
 "Nuevo Rol": "Nouveau R\u00f4le", 
 "Informaci\u00f3n de Rol": "Informations sur le R\u00f4le", 
 "Rol borrado correctamente": "R\u00f4le supprim\u00e9 avec succ\u00e8s", 
 "Rol administrador": "R\u00f4le d'administrateur", 
 "Sincronizar": "Synchroniser", 
 "¡Sincronizar datos!": "¡Synchroniser les donn\u00e9es!", 
 "Nombre, Organizaci\u00f3n y subscripci\u00f3n van a ser modificados por el valor que tengan en la plataforma Kiconex \u00bfEst\u00e1 seguro de esto?": "Le nom, l'organisation et l'abonnement seront modifi\u00e9s par la valeur qu'ils ont dans la plate-forme Kiconex. \u00cates-vous s\u00fbr de cela ?", 
 "Usuario guardado correctamente": "Utilisateur enregistr\u00e9 avec succ\u00e8s", 
 "Nuevo usuario": "Nouvel utilisateur", 
 "Editando usuario": "Modifier l'utilisateur", 
 "Software": "Logiciel", 
 "Crear": "Cr\u00e9er", 
 "Ver": "Regarder", 
 "Finalizar": "Finir", 
 "Editar comercial": "Modifier la publicit\u00e9", 
 "Gesti\u00f3n de placas de f\u00e1brica": "Gestion des plaques d'immatriculation d'usine", 
 "Asignaciones de f\u00e1brica": "Affectations d'usine", 
 "Asignaci\u00f3n de placas de f\u00e1brica": "Attribution des plaques d'usine", 
 "Gesti\u00f3n de placas de administraci\u00f3n": "Direction du conseil d'administration", 
 "mykiconex": "mykiconex", 
 "Gesti\u00f3n con plataforma mykiconex": "Gestion avec la plateforme mykiconex", 
 "M2M": "M2M", 
 "Ver M2M": "Voir M2M", 
 "Ver estado": "Afficher l'\u00e9tat", 
 "Ver listado pools": "Voir la liste des pools", 
 "Refrescar pools": "Rafra\u00eechir les piscines", 
 "Actualizar token": "Jeton d'actualisation", 
 "VPN": "VPN", 
 "Chat": "Chat", 
 "Ver privilegios": "Afficher les privil\u00e8ges", 
 "Editar privilegios": "Modifier les privil\u00e8ges", 
 "Gesti\u00f3n de sistema": "La gestion du syst\u00e8me", 
 "Gesti\u00f3n de usuarios": "Gestion des utilisateurs", 
 "Ver acceso a chat": "Afficher l'acc\u00e8s au chat", 
 "Ver acceso a soporte": "Voir l'acc\u00e8s au support", 
 "Ver acceso a VPN": "Voir l'acc\u00e8s VPN", 
 "Gesti\u00f3n de tarjetas M2M": "Gestion des cartes M2M", 
 "Autorizaci\u00f3n": "Autorisation", 
 "Portugu\u00e9s": "Portugais", 
 "Italiano": "Italien", 
 "Fecha primera contrataci\u00f3n": "Premi\u00e8re date d'embauche", 
 "Fecha \u00faltima renovaci\u00f3n": "Derni\u00e8re date de renouvellement", 
 "Intervalo de renovaci\u00f3n (a\u00f1os)": "Intervalle de renouvellement (ann\u00e9es)", 
 "Fecha primera conexi\u00f3n": "Premi\u00e8re date de connexion", 
 "Privilegios guardados correctamente": "Privil\u00e8ges enregistr\u00e9s correctement", 
 "Rol actualizado correctamente": "R\u00f4le mis \u00e0 jour correctement", 
 "N\u00famero Pedido": "Num\u00e9ro de commande", 
 "Asunto": "Affaire", 
 "Asignado a": "Assign\u00e9 \u00e0", 
 "Fecha de Creaci\u00f3n": "Date de creation", 
 "Pendiente": "En attente", 
 "Cambiar Estado": "Changer le statut", 
 "Estado del Pedido actualizado correctamente": "Commandez l'\u00e9tat mis \u00e0 jour correctement", 
 "Cliente Final": "Client final", 
 "Referencia Presupuesto": "R\u00e9f\u00e9rence budg\u00e9taire", 
 "Precio Total": "Prix ​​total", 
 "Ver en CRM": "Voir dans CRM", 
 "N\u00famero Presupuesto": "Num\u00e9ro de budget", 
 "En Proceso": "En proc\u00e8s", 
 "Anulado": "Annul\u00e9", 
 "Cambiar estado del pedido": "Modifier l'\u00e9tat de la commande", 
 "Pedidos": "Ordres", 
 "Gesti\u00f3n de pedidos": "Gestion des commandes", 
 "Error en la operaci\u00f3n": "Erreur de fonctionnement", 
 "UUID de placa ya en uso": "UUID de plaque d\u00e9j\u00e0 utilis\u00e9", 
 "Nueva Placa Guardada": "Nouvelle assiette sauv\u00e9e", 
 "Placa actualizada": "Assiette mise \u00e0 jour", 
 "Placa borrada": "Assiette supprim\u00e9e", 
 "Placas actualizadas": "Plaques mises \u00e0 jour", 
 "SSID de kiwi ya en uso": "Kiwi ssid d\u00e9j\u00e0 utilis\u00e9", 
 "Nuevo KiWi Guardado": "Nouveau kiwi sauv\u00e9", 
 "KiWi actualizado": "Kiwi mis \u00e0 jour", 
 "KiWi borrado": "Kiwi supprim\u00e9", 
 "Token actualizado": "Jeton mis \u00e0 jour", 
 "Nombre de rol ya en uso": "Nom du r\u00f4le d\u00e9j\u00e0 utilis\u00e9", 
 "Rol actualizado": "R\u00f4le mis \u00e0 jour", 
 "No puede eliminar un rol con dependencias": "Ne peut pas \u00e9liminer un r\u00f4le avec les d\u00e9pendances", 
 "La clave ha expirado.": "La cl\u00e9 a expir\u00e9.", 
 "Usuario no encontrado.": "Utilisateur non trouv\u00e9.", 
 "Las contrase\u00f1as son obligatorias.": "Les mots de passe sont obligatoires.", 
 "Las contrase\u00f1as no coinciden.": "Les mots de passe ne correspondent pas.", 
 "Nombre de usuario ya en uso": "Nom d'utilisateur d\u00e9j\u00e0 utilis\u00e9", 
 "Error del servidor.": "Erreur du serveur.", 
 "El usuario no ha sido activado.": "L'utilisateur n'a pas \u00e9t\u00e9 activ\u00e9.", 
 "Inicio de sesi\u00f3n con \u00e9xito": "Connexion r\u00e9ussie", 
 "Contrase\u00f1a incorrecta": "Mot de passe incorrect", 
 "Fecha Renovaci\u00f3n": "Date de renouvellement", 
 "Tiempo Restante": "Temps Restant", 
 " meses restantes": " mois restants", 
 "Pedidos a expirar": "Ordres d'expirer", 
 "Descargar Informe": "Rapport de t\u00e9l\u00e9chargement", 
 "Renovaciones": "R\u00e9novations", 
 "Pr\u00f3ximo mes": "Mois prochain", 
 "Pr\u00f3ximos 3 meses": "3 mois suivants", 
 "Pr\u00f3ximos 6 meses": "6 mois suivants", 
 "Pr\u00f3ximo a\u00f1o": "L'ann\u00e9e prochaine", 
 "Ver renovaciones": "Voir les r\u00e9novations", 
 "Caducada": "Expir\u00e9", 
 " d\u00edas restantes": " jours restants", 
 "Aceptar": "Accepter", 
 "Reiniciar": "Red\u00e9marrage", 
 "Sin organizaci\u00f3n": "Aucune organisation", 
 "Ver pools": "Voir les pools", 
 "Alarmas": "Alarmes", 
 "Alarmas M2M": "Alarmes M2M", 
 "Sin alertas": "Sans alerte", 
 "Advertencia": "Avertissement", 
 "Critica": "Critique", 
 "Renovaciones expiradas": "R\u00e9novations expir\u00e9es", 
 "Pedidos pendientes": "Les ordres en attente", 
 "Tarjetas desactivadas desde hace mas de 12 meses": "Cartes d\u00e9sactiv\u00e9es pendant plus de 12 mois", 
 "Ver m\u00e1s": "Voir plus", 
 "Creaci\u00f3n": "Cr\u00e9ation", 
 "Actualizaci\u00f3n": "Actualisation", 
 "Borrado": "Effac\u00e9", 
 "Cambio de estado": "Changement de statut", 
 "Cambio de contrase\u00f1a": "Changement de mot de passe", 
 "Sincronizaci\u00f3n": "Synchronisation", 
 "Sin acci\u00f3n": "Pas d'action", 
 "Fecha": "Date", 
 "Acci\u00f3n": "Action", 
 "Tipo de objeto": "Type d'objet", 
 "Identificador": "Identifiant", 
 "Placa": "Plaque", 
 "Hace 3 horas": "il y a 3 heures", 
 "Hace 1 d\u00eda": "il y a 1 jour", 
 "Hace 3 d\u00edas": "Il y \u00e0 3 jours", 
 "Hace 1 semana": "Il ya 1 semaine", 
 "Actividad": "Activit\u00e9", 
 "Listado": "Liste", 
 "Ver actividad": "Voir l'activit\u00e9", 
 "Privilegio": "Privil\u00e8ge", 
 "No es posible personificar desde un personificado.": "Il n'est pas possible de personnifier \u00e0 partir d'un personnifi\u00e9.", 
 "Personificaci\u00f3n": "Personnification", 
 "Personificando al usuario": "Personcifier l'utilisateur", 
 "Volver a la sesi\u00f3n": "Aller \u00e0 la session", 
 "Personificar": "Personnifier", 
 "No es posible personificarse a si mismo.": "Il n'est pas possible de vous personnifier.", 
 "Comienzo Personificaci\u00f3n": "Personne de d\u00e9part", 
 "Fin Personificaci\u00f3n": "Fin de personnification", 
 "Ajustes": "Param\u00e8tres", 
 "Actualizar listado": "Liste de mise \u00e0 jour", 
 "Refrescar pedidos": "Rafra\u00eechir les commandes", 
 "Refrescar ordenes de trabajo": "Rafra\u00eechir les ordres de travail", 
 "de": "de", 
 "filtrados de": "filtr\u00e9", 
 "Actualizar listado de organizaciones": "Mettre \u00e0 jour la liste des organisations", 
 "Actualizar nombre, plan y organizaci\u00f3n de kibox": "Mettre \u00e0 jour le nom, le plan et l'organisation de Kibox", 
 "Actualizar listado de pedidos": "Mettre \u00e0 jour la liste des commandes", 
 "Actualizar listado de ordenes de trabajo": "Mettre \u00e0 jour la liste des ordres de travail", 
 "Actualizaci\u00f3n de listados": "Liste de mise \u00e0 jour", 
 "Actualizado con exito": "Mis \u00e0 jour avec succ\u00e8s", 
 "Activa": "Actif", 
 "Sin intervalo": "Pas d'intervalle", 
 "Mostrar todo": "Tout montrer", 
 "Estado de suscripci\u00f3n no compatible con el intervalo seleccionado": "\u00c9tat d'abonnement non compatible avec l'intervalle s\u00e9lectionn\u00e9", 
 "Estado del plan": "Statut de plan", 
 "Caducado": "Fin du temps", 
 "Inactivo": "Inactif", 
 "Cancelado": "Annul\u00e9", 
 "No procede": "Il ne continue pas", 
 "¡Orden de trabajo erronea!": "Ordre de travail erron\u00e9!", 
 "Rellene el formulario correctamente": "Remplissez correctement le formulaire", 
 "Orden de trabajo": "Ordre de travail", 
 "Su sesi\u00f3n va a ser cerrada": "Votre session sera ferm\u00e9e", 
 "Su sesi\u00f3n se cerrar\u00e1 para aplicar los cambios": "Vous serez d\u00e9connect\u00e9 pour appliquer les modifications", 
 "Inicie sesi\u00f3n de nuevo": "Connectez-vous \u00e0 nouveau", 
 "Posee una versi\u00f3n no compatible": "Il a une version non compatible", 
 "Consumo": "Consommation", 
 "Consumo de la SIM": "Consommation SIM", 
 "Mensual": "Mensuel", 
 "Diario": "Agenda", 
 "UUID ya en uso": "UUID d\u00e9j\u00e0 utilis\u00e9", 
 "VPN creada correctamente": "VPN cr\u00e9\u00e9 avec succ\u00e8s", 
 "VPN actualizada correctamente": "VPN mis \u00e0 jour avec succ\u00e8s", 
 "Contrase\u00f1a copiada al portapapeles": "Mot de passe copi\u00e9 dans le presse-papiers", 
 "Fallo al copiar la contrase\u00f1a": "\u00c9chec de la copie du mot de passe", 
 "Nueva conexi\u00f3n VPN": "Nouvelle connexion VPN", 
 "Regenerar contrase\u00f1a": "R\u00e9g\u00e9n\u00e9rer le mot de passe", 
 "Contrase\u00f1a VPN": "Mot de passe VPN", 
 "Copie la contrase\u00f1a antes de guardar, no volver\u00e1 a ser visible": "Copiez le mot de passe avant de l'enregistrer, il ne sera plus visible", 
 "VPN (antigua)": "VPN (ancien)", 
 "Ver acceso a VPN (antigua)": "Afficher l'acc\u00e8s VPN (ancien)", 
 "Ver VPN (antigua)": "Voir VPN (ancien)", 
 "Ver VPN": "Voir VPN", 
 "Crear VPN": "Cr\u00e9er un VPN", 
 "Editar VPN": "Modifier le VPN", 
 "Ver informaci\u00f3n": "Voir les informations", 
 "Informaci\u00f3n placas": "Informations sur la plaque", 
 "Modelo de hardware": "Mod\u00e8le de mat\u00e9riel", 
 "Fecha de compilaci\u00f3n": "Date de construction", 
 "Versi\u00f3n de Daemon": "Version d\u00e9mon", 
 "Fecha de actualizaci\u00f3n": "Date de mise \u00e0 jour", 
 "Actualizado": "Mis \u00e0 jour", 
 "No actualizado": "Pas \u00e0 jour", 
 "Descargar fichero de configuraci\u00f3n": "T\u00e9l\u00e9charger le fichier de configuration", 
 "Nunca": "Jamais", 
 "Dispositivo": "Appareil", 
 },
  "plural": "(n != 1)"
  };
  })(this);