(function (exports) { 
   exports.DE_DE_KICORE = {
 "catalog": { 
"Error 404": "Fehler 404", 
 "Detalles Administrativos": "Verwaltungsdetails", 
 "Detalles  M2M": "Details zu M2M", 
 "Search": "Suche", 
 "Reset": "Zur\u00fccksetzen", 
 "Instalaci\u00f3n/Pedido": "Ger\u00e4t/Bestellung", 
 "Cliente": "Klient", 
 "Estado": "Status", 
 "Desactivada": "Behindert", 
 "Activada": "Aktiviert", 
 "Inactiva nueva": "Inaktiv neu", 
 "Lista para activaci\u00f3n": "Bereit zur Aktivierung", 
 "Desconocido": "Unbekannt", 
 "Consumo diario": "T\u00e4glicher Verbrauch", 
 "Consumo mensual": "Monatlicher Verbrauch", 
 "Comienzo \u00faltima conexi\u00f3n": "Start der letzten Verbindung", 
 "Fin \u00faltima conexi\u00f3n": "Ende der letzten Verbindung", 
 "Compa\u00f1\u00eda de red": "Netzwerkunternehmen", 
 "C\u00f3digo de pa\u00eds": "Landesvorwahl", 
 "Tipo de red": "Netzwerkart", 
 "Tipo de tarifa": "Geb\u00fchrenart", 
 "Zona 1": "Zone 1", 
 "Sin asignar": "Nicht zugeordnet", 
 "Cambiar ciclo vida": "Lebenszyklus \u00e4ndern", 
 "Por favor, seleccione una opci\u00f3n": "Bitte w\u00e4hle eine Option", 
 "Desactivar": "Deaktivieren", 
 "Activar": "Aktivieren Sie", 
 "Test": "Pr\u00fcfen", 
 "Estado GSM": "GSM-Status", 
 "Estado GPRS": "GPRS-Status", 
 "Buscar": "Suche", 
 "Borrar": "L\u00f6schen", 
 "Nombre": "Name", 
 "Descripci\u00f3n": "Beschreibung", 
 "UUID placa": "UUID Platte", 
 "Estado instalaci\u00f3n": "Ger\u00e4testatus", 
 "Desconectada": "Getrennt", 
 "Conectada": "In Verbindung gebracht", 
 "Deshabilitada": "Behindert", 
 "Habilitada": "Erm\u00f6glicht", 
 "Detalles": "Einzelheiten", 
 "No se pudieron actualizar los datos": "Daten konnten nicht aktualisiert werden", 
 "Pools actualizados correctamente": "Pools erfolgreich aktualisiert", 
 "Tarjetas activas / tarjetas totales": "Aktive Karten / Karten insgesamt", 
 "Consumo del pool / Pool total": "Poolverbrauch / Gesamtpool", 
 "Gasto estimado del pool": "Gesch\u00e4tzte Poolkosten", 
 "\u00daltima actualizaci\u00f3n": "Letztes Update", 
 "Refrescar": "Aktualisierung", 
 "Actualizar token de acceso": "Zugriffstoken aktualisieren", 
 "Por favor, complete este campo": "Bitte f\u00fcllen Sie dieses Feld aus", 
 "Enviar": "Schicken", 
 "Placa creada correctamente": "Board erfolgreich erstellt", 
 "Placa actualizada correctamente": "Board erfolgreich aktualisiert", 
 "MAC err\u00f3nea": "Falscher MAC", 
 "SSID err\u00f3neo": "Falsche SSID", 
 "Nuevo kiwi": "Neue Kiwi", 
 "Informaci\u00f3n de kiwi": "Kiwi-Informationen", 
 "Guardar": "Speichern", 
 "UUID err\u00f3neo": "Falsche UUID", 
 "Nuevo kibox": "Neue Kibox", 
 "Informaci\u00f3n de kibox con UUID": "Kibox-Informationen mit UUID", 
 "Informaci\u00f3n de kibox": "Kibox-Informationen", 
 "Habr\u00e1 mas de una placa con ese uuid.\u00bfEst\u00e1 seguro de esto?": "Es wird mehr als eine board mit diesem Uuid geben. Bist du dir dessen sicher?", 
 "Modelo placa": "Board-Modell", 
 "Seleccionar opci\u00f3n": "W\u00e4hle eine Option", 
 "Tarda demasiado en encenderse": "Das Einschalten dauert zu lange", 
 "Usuario": "Benutzer", 
 "Nombre de usuario": "Nutzername", 
 "Rol": "Rolle", 
 "Organizaci\u00f3n": "Organisation", 
 "Apellidos": "Nachname", 
 "Email": "Email", 
 "Complete los campos obligatorios": "F\u00fcllen Sie die erforderlichen Felder aus", 
 "Las contrase\u00f1as tienen que ser iguales": "Passw\u00f6rter m\u00fcssen gleich sein", 
 "Nombre de Usuario": "Nutzername", 
 "Correo electr\u00f3nico": "Email", 
 "Contrase\u00f1a": "Passwort", 
 "Repetir contrase\u00f1a": "Passwort wiederholen", 
 "Selecciona organizaci\u00f3n": "Organisation ausw\u00e4hlen", 
 "Nivel": "Stufe", 
 "Selecciona un rol": "W\u00e4hlen Sie eine Rolle aus", 
 "Superadministrador": "H\u00f6chster Vorgesetzter", 
 "Administrador": "Administrator", 
 "Operador": "Operator", 
 "Visitante": "Besucher", 
 "Activo": "Aktiv", 
 "Crear cuenta": "Benutzerkonto anlegen", 
 "Inicio": "Start", 
 "Nuevo equipo": "Neues Ger\u00e4t", 
 "Stock": "Aktie", 
 "Asignaciones": "Zuordnungen", 
 "Lista equipos": "Ger\u00e4teliste", 
 "Instalaciones": "Ger\u00e4te", 
 "Listado M2M": "M2M Auff\u00fchren", 
 "Listado Pools": "Pools Auff\u00fchren", 
 "Soporte": "Mittel", 
 "Crear Usuario": "Benutzer erstellen", 
 "Lista Usuarios": "Benutzer auflisten", 
 "error": "Fehler", 
 "Informaci\u00f3n de tarjeta M2M con icc": "Informationen der M2M-Karte mit icc", 
 "Alias": "Alias", 
 "Life Cycle Status": "Lebenszyklusstatus", 
 "Pais": "Land", 
 "Plan": "Planen", 
 "Estado Actual": "Aktueller Status", 
 "\u00daltimo evento": "Letzte Veranstaltung", 
 "Tecno.Conexi\u00f3n": "Verbindungstechnik", 
 "\u00daltima Conexi\u00f3n": "Letzte Verbindung", 
 "Complete los campos": "F\u00fcllen Sie die Felder aus", 
 "Inicio de sesi\u00f3n exitoso": "Anmeldung erfolgreich", 
 "Introduzca un nombre de usuario": "Gebe einen Benutzernamen ein", 
 "Introduzca una contrase\u00f1a": "Geben Sie ein Passwort ein", 
 "Iniciar sesi\u00f3n": "Anmeldung", 
 "Eliminar": "L\u00f6schen", 
 "Editar": "Bearbeiten", 
 "Asignar": "Zuordnen", 
 "N\u00famero de serie err\u00f3neo": "Falsche Seriennummer", 
 "Nuevo KiWi": "Neue Kiwi", 
 "Pedido": "Befehl", 
 "N serie": "Seriennummer", 
 "Fecha fabricaci\u00f3n": "Herstellungsdatum", 
 "Estado de fabricaci\u00f3n": "Fertigungsstatus", 
 "KiWi terminado": "Kiwi ist fertig", 
 "No se ha podido terminar": "Konnte nicht beenden", 
 "Terminar": "Beenden", 
 "Version hardware": "Hardware Version", 
 "Modelo": "Modell", 
 "Tipo USB": "USB-Typ", 
 "Instalacion": "Ger\u00e4t", 
 "Cliente kiconex": "Kiconex-Kunde", 
 "Cliente final": "Endkunde", 
 "N\u00famero de serie": "Seriennummer", 
 "kiconex plan": "Kiconex planen", 
 "Nº dispositivos": "Anzahl der Ger\u00e4te", 
 "Cuadro": "Rahmen", 
 "Placa terminada": "Fertiges Brett", 
 "Opcionales": "Optionen", 
 "Notas": "Anmerkungen", 
 "Crear nuevo dispositivo": "Neues Ger\u00e4t erstellen", 
 "Bienvenido a kicore ¡la plataforma de gesti\u00f3n de Kiconex!": "Willkommen bei kicore, der Verwaltungsplattform von Kiconex!", 
 "Ver en kiconex": "In kiconex ansehen", 
 "ICC err\u00f3neo": "Falsches ICC", 
 "Nombre instalaci\u00f3n": "Ger\u00e4tename", 
 "Nº serie": "Seriennummer", 
 "Versi\u00f3n hardware": "Hardware Version", 
 "Ninguno": "Keiner", 
 "Chino": "C", 
 "Plan kiconex": "Kiconex planen", 
 "Basic": "Basic", 
 "Professional": "Proffesional", 
 "Premium": "Premium", 
 "Versi\u00f3n OEM": "OEM Version", 
 "No": "Nein", 
 "S\u00ed": "Ja", 
 "Cable": "Kabel", 
 "Fabricado": "Hergestellt", 
 "Asignado": "Zugewiesen", 
 "Terminado": "Fertig", 
 "Eliminado": "Gel\u00f6scht", 
 "Volver": "Zur\u00fcckkehren", 
 "¡MAC err\u00f3nea!": "Falscher MAC!", 
 "¡SSID err\u00f3neo!": "Falscher SSID!", 
 "¡UUID err\u00f3neo!": "Falscher UUID!", 
 "¡ICC err\u00f3neo!": "Falscher ICC!", 
 "¡N\u00famero de serie err\u00f3neo!": "Falsche Seriennummer!", 
 "Grupo comercial": "Handelsgruppe", 
 "Habilitaci\u00f3n": "Bef\u00e4higung", 
 "F\u00e1brica": "Fabrik", 
 "Administraci\u00f3n": "Verwaltung", 
 "Gesti\u00f3n": "Management", 
 "Usuarios": "Benutzer", 
 "Lista kibox": "Kibox auff\u00fchren", 
 "Lista kiwi": "Kiwi auff\u00fchren", 
 "Kibox asignados": "Zugewiesene Kiboxen", 
 "Kiwis asignados": "Zugeteilte Kiwis", 
 "No se conecta al WiFi": "Stellt keine WLAN-Verbindung her", 
 "Este pedido debe salir con una pegatina extra": "Diese Bestellung muss mit einem zus\u00e4tzlichen Aufkleber versehen sein", 
 "Cambiar estado de la SIM": "SIM-Status \u00e4ndern", 
 "Estado de la SIM": "SIM-Status", 
 "Fruter\u00eda fulanito": "Beispiel Obstladen", 
 "Token actualizado correctamente": "Token erfolgreich aktualisiert", 
 "Usuario creado correctamente": "Benutzer erfolgreich erstellt", 
 "No hay respuesta del servidor": "Es kommt keine Antwort vom Server", 
 "Error": "Fehler", 
 "Error al cerrar sesi\u00f3n": "Fehler beim Abmelden", 
 "Seleccionar idioma": "Sprache ausw\u00e4hlen", 
 "Espa\u00f1ol": "Spanisch", 
 "Ingl\u00e9s": "Englisch", 
 "Franc\u00e9s": "Franz\u00f6sisch", 
 "Alem\u00e1n": "Deutsch", 
 "UUID": "UUID", 
 "Nombre comercial": "Handelsname", 
 "Pedido comercial": "Handelsordnung", 
 "N\u00famero de serie comercial": "Kommerzielle Seriennummer", 
 "Modelo del equipo": "Ausstattungsmodell", 
 "Estado Comercial": "Kaufm\u00e4nnischer Status", 
 "Fecha Conexi\u00f3n": "Datumsverbindung", 
 "Subscripci\u00f3n": "Abonnement", 
 "Comercial": "Werbung", 
 "General": "Allgemein", 
 "Placa borrada correctamente": "Platte erfolgreich gel\u00f6scht", 
 "Sistema": "System", 
 "Privilegios": "Privilegien", 
 "Roles": "Rollen", 
 "Usuario borrado correctamente": "Benutzer erfolgreich gel\u00f6scht", 
 "Cambiar contrase\u00f1a": "Kennwort \u00e4ndern", 
 "Columnas": "S\u00e4ulen", 
 "Sin informaci\u00f3n": "Keine Information", 
 "Cerrar": "Schlie\u00dfen", 
 "Cambiar": "\u00c4ndern", 
 "Por favor, seleccione una opcion": "Bitte w\u00e4hle eine Option", 
 "¡Las contrase\u00f1as deben coincidir!": "¡Die Passw\u00f6rter m\u00fcssen \u00fcbereinstimmen!", 
 "La contrase\u00f1a es un campo obligatorio": "Passwort ist ein Pflichtfeld", 
 "success": "Erfolg", 
 "Contrase\u00f1a actualizada correctamente": "Passwort erfolgreich aktualisiert", 
 "Las contrase\u00f1as deben coincidir": "Die Passw\u00f6rter m\u00fcssen \u00fcbereinstimmen", 
 "Cambio de contrase\u00f1a usuario": "\u00c4nderung des Benutzerkennworts", 
 "Debe seleccionar un rol": "Sie m\u00fcssen eine Rolle ausw\u00e4hlen", 
 "Rol creado correctamente": "Rolle erfolgreich erstellt", 
 "Nuevo Rol": "Neue Rolle", 
 "Informaci\u00f3n de Rol": "Rolleninformationen", 
 "Rol borrado correctamente": "Rolle erfolgreich gel\u00f6scht", 
 "Rol administrador": "Administratorrolle", 
 "Sincronizar": "Synchronisieren", 
 "¡Sincronizar datos!": "¡Daten synchronisieren!", 
 "Nombre, Organizaci\u00f3n y subscripci\u00f3n van a ser modificados por el valor que tengan en la plataforma Kiconex \u00bfEst\u00e1 seguro de esto?": "Name, Organisation und Abonnement werden durch den Wert ge\u00e4ndert, den sie auf der Kiconex-Plattform haben. Sind Sie sich sicher?", 
 "Usuario guardado correctamente": "Benutzer erfolgreich gespeichert", 
 "Nuevo usuario": "Neuer Benutzer", 
 "Editando usuario": "Benutzer bearbeiten", 
 "Software": "Software", 
 "Crear": "Erstellen", 
 "Ver": "Sehen", 
 "Finalizar": "Beenden", 
 "Editar comercial": "Werbung bearbeiten", 
 "Gesti\u00f3n de placas de f\u00e1brica": "Verwaltung von Werkskennzeichen", 
 "Asignaciones de f\u00e1brica": "Fabrikzuweisungen", 
 "Asignaci\u00f3n de placas de f\u00e1brica": "Zuordnung von Fabrikschildern", 
 "Gesti\u00f3n de placas de administraci\u00f3n": "Gesch\u00e4ftsf\u00fchrung des Verwaltungsrats", 
 "mykiconex": "mykiconex", 
 "Gesti\u00f3n con plataforma mykiconex": "Verwaltung mit mykiconex-Plattform", 
 "M2M": "M2M", 
 "Ver M2M": "Siehe M2M", 
 "Ver estado": "Status anzeigen", 
 "Ver listado pools": "Siehe Poolliste", 
 "Refrescar pools": "Pools auffrischen", 
 "Actualizar token": "Aktualisierungstoken", 
 "VPN": "VPN", 
 "Chat": "Chat", 
 "Ver privilegios": "Privilegien anzeigen", 
 "Editar privilegios": "Berechtigungen bearbeiten", 
 "Gesti\u00f3n de sistema": "Systemmanagement", 
 "Gesti\u00f3n de usuarios": "Benutzerverwaltung", 
 "Ver acceso a chat": "Chat-Zugriff anzeigen", 
 "Ver acceso a soporte": "Siehe Zugang zum Support", 
 "Ver acceso a VPN": "Siehe VPN-Zugriff", 
 "Gesti\u00f3n de tarjetas M2M": "M2M-Kartenverwaltung", 
 "Autorizaci\u00f3n": "Genehmigung", 
 "Portugu\u00e9s": "Portugiesisch", 
 "Italiano": "Italienisch", 
 "Fecha primera contrataci\u00f3n": "Erster Einstellungsdatum", 
 "Fecha \u00faltima renovaci\u00f3n": "Letzte Erneuerungsdatum", 
 "Intervalo de renovaci\u00f3n (a\u00f1os)": "Erneuerungsintervall (Jahre)", 
 "Fecha primera conexi\u00f3n": "Erster Verbindungsdatum", 
 "Privilegios guardados correctamente": "Privilegien korrekt gespeichert", 
 "Rol actualizado correctamente": "Rolle korrekt aktualisiert", 
 "N\u00famero Pedido": "Bestellnummer", 
 "Asunto": "Aff\u00e4re", 
 "Asignado a": "Zugewiesen an", 
 "Fecha de Creaci\u00f3n": "Erstellungsdatum", 
 "Pendiente": "Rechtsh\u00e4ngig", 
 "Cambiar Estado": "Status \u00e4ndern", 
 "Estado del Pedido actualizado correctamente": "Bestellstatus ordnungsgem\u00e4\u00df aktualisiert", 
 "Cliente Final": "Endkunde", 
 "Referencia Presupuesto": "Budgetreferenz", 
 "Precio Total": "Gesamtpreis", 
 "Ver en CRM": "Siehe in CRM", 
 "N\u00famero Presupuesto": "Budgetnummer", 
 "En Proceso": "Im Gange", 
 "Anulado": "Abgesagt", 
 "Cambiar estado del pedido": "Bestellstatus \u00e4ndern", 
 "Pedidos": "Auftr\u00e4ge", 
 "Gesti\u00f3n de pedidos": "Bestellmanagement", 
 "Error en la operaci\u00f3n": "Betriebsfehler", 
 "UUID de placa ya en uso": "Plaque Uuid bereits verwendet", 
 "Nueva Placa Guardada": "Neue gespeicherte Platte", 
 "Placa actualizada": "Aktualisierte Platte", 
 "Placa borrada": "Gel\u00f6schte Platte", 
 "Placas actualizadas": "Aktualisierte Teller", 
 "SSID de kiwi ya en uso": "Kiwi ssid bereits verwendet", 
 "Nuevo KiWi Guardado": "Neue Kiwi gespeichert", 
 "KiWi actualizado": "Kiwi aktualisiert", 
 "KiWi borrado": "Kiwi gel\u00f6scht", 
 "Token actualizado": "Token aktualisiert", 
 "Nombre de rol ya en uso": "Rollenname bereits verwendet", 
 "Rol actualizado": "Rolle aktualisiert", 
 "No puede eliminar un rol con dependencias": "Kann keine Rolle mit Abh\u00e4ngigkeiten beseitigen", 
 "La clave ha expirado.": "Der Schl\u00fcssel ist abgelaufen.", 
 "Usuario no encontrado.": "Benutzer nicht gefunden.", 
 "Las contrase\u00f1as son obligatorias.": "Passw\u00f6rter sind obligatorisch.", 
 "Las contrase\u00f1as no coinciden.": "Passw\u00f6rter stimmen nicht \u00fcberein.", 
 "Nombre de usuario ya en uso": "Benutzername bereits vergeben", 
 "Error del servidor.": "Serverfehler.", 
 "El usuario no ha sido activado.": "Der Benutzer wurde nicht aktiviert.", 
 "Inicio de sesi\u00f3n con \u00e9xito": "Anmeldung erfolgreich", 
 "Contrase\u00f1a incorrecta": "Falsches Passwort", 
 "Fecha Renovaci\u00f3n": "Verl\u00e4ngerungsdatum", 
 "Tiempo Restante": "\u00dcbrige Zeit", 
 " meses restantes": " verbleibende Monate", 
 "Pedidos a expirar": "Bestellungen ablaufen", 
 "Descargar Informe": "Bericht herunterladen", 
 "Renovaciones": "Renovierungsarbeiten", 
 "Pr\u00f3ximo mes": "N\u00e4chsten Monat", 
 "Pr\u00f3ximos 3 meses": "N\u00e4chste 3 Monate", 
 "Pr\u00f3ximos 6 meses": "N\u00e4chste 6 Monate", 
 "Pr\u00f3ximo a\u00f1o": "N\u00e4chstes Jahr", 
 "Ver renovaciones": "Siehe Renovierungsarbeiten", 
 "Caducada": "Abgelaufen", 
 " d\u00edas restantes": " verbleibende tage", 
 "Aceptar": "Akzeptieren", 
 "Reiniciar": "Neu starten", 
 "Sin organizaci\u00f3n": "Keine Organisation", 
 "Ver pools": "Siehe Pools", 
 "Alarmas": "Alarm", 
 "Alarmas M2M": "Alarm M2M", 
 "Sin alertas": "Ohne Warnungen", 
 "Advertencia": "Warnung", 
 "Critica": "Kritik", 
 "Renovaciones expiradas": "Abgelaufene Renovierungsarbeiten", 
 "Pedidos pendientes": "Ausstehende Bestellungen", 
 "Tarjetas desactivadas desde hace mas de 12 meses": "Karten deaktiviert l\u00e4nger als 12 Monate", 
 "Ver m\u00e1s": "Mehr sehen", 
 "Creaci\u00f3n": "Schaffung", 
 "Actualizaci\u00f3n": "Aktualisieren", 
 "Borrado": "Gel\u00f6scht", 
 "Cambio de estado": "Status \u00c4nderung", 
 "Cambio de contrase\u00f1a": "\u00c4nderung des Passworts", 
 "Sincronizaci\u00f3n": "Synchronisation", 
 "Sin acci\u00f3n": "Keine Aktion", 
 "Fecha": "Datum", 
 "Acci\u00f3n": "Aktion", 
 "Tipo de objeto": "Art des Objekts", 
 "Identificador": "Kennung", 
 "Placa": "Platte", 
 "Hace 3 horas": "vor 3 Stunden", 
 "Hace 1 d\u00eda": "Vor 1 Tag", 
 "Hace 3 d\u00edas": "vor 3 Tagen", 
 "Hace 1 semana": "Vor 1 Woche", 
 "Actividad": "Aktivit\u00e4t", 
 "Listado": "Auff\u00fchren", 
 "Ver actividad": "Siehe Aktivit\u00e4t", 
 "Privilegio": "Privileg", 
 "No es posible personificar desde un personificado.": "Es ist nicht m\u00f6glich, von einem Personified zu personifizieren.", 
 "Personificaci\u00f3n": "Personifikation", 
 "Personificando al usuario": "Personifizierung des Benutzers", 
 "Volver a la sesi\u00f3n": "In die Sitzung gehen", 
 "Personificar": "Personifizieren", 
 "No es posible personificarse a si mismo.": "Es ist nicht m\u00f6glich, sich selbst zu personifizieren.", 
 "Comienzo Personificaci\u00f3n": "Person starten", 
 "Fin Personificaci\u00f3n": "Endpersonifikation", 
 "Ajustes": "Einstellungen", 
 "Actualizar listado": "Aktualisierungsliste", 
 "Refrescar pedidos": "Bestellungen aktualisieren", 
 "Refrescar ordenes de trabajo": "Bestellungen Arbeitsauftrag", 
 "de": "von", 
 "filtrados de": "gefiltert von", 
 "Actualizar listado de organizaciones": "Aktualisieren Sie die Liste der Organisationen", 
 "Actualizar nombre, plan y organizaci\u00f3n de kibox": "Aktualisieren Sie den Namen, Plan und die Organisation von Kibox", 
 "Actualizar listado de pedidos": "Aktualisieren Sie die Auftr\u00e4ge", 
 "Actualizar listado de ordenes de trabajo": "Aktualisieren Sie die Arbeitsauftrag", 
 "Actualizaci\u00f3n de listados": "Listen -Update", 
 "Actualizado con exito": "Mit Erfolg aktualisiert", 
 "Activa": "Aktiv", 
 "Sin intervalo": "Kein Intervall", 
 "Mostrar todo": "Zeigen Sie alles", 
 "Estado de suscripci\u00f3n no compatible con el intervalo seleccionado": "Abonnementstatus nicht mit dem ausgew\u00e4hlten Intervall kompatibel", 
 "Estado del plan": "Planstatus", 
 "Caducado": "Zeitlich abgestimmt", 
 "Inactivo": "Leerlauf", 
 "Cancelado": "Abgesagt", 
 "No procede": "Es geht nicht fort", 
 "¡Orden de trabajo erronea!": "Irrt\u00fcmlich Arbeitsauftrag!", 
 "Rellene el formulario correctamente": "F\u00fcllen Sie das Formular richtig aus", 
 "Orden de trabajo": "Arbeitsauftrag", 
 "Su sesi\u00f3n va a ser cerrada": "Ihre Sitzung wird geschlossen", 
 "Su sesi\u00f3n se cerrar\u00e1 para aplicar los cambios": "Sie werden abgemeldet, um die \u00c4nderungen zu \u00fcbernehmen", 
 "Inicie sesi\u00f3n de nuevo": "Melden Sie sich erneut an", 
 "Posee una versi\u00f3n no compatible": "Es hat eine nicht kompatible Version", 
 "Consumo": "Verbrauch", 
 "Consumo de la SIM": "SIM-Verbrauch", 
 "Mensual": "Monatlich", 
 "Diario": "Tagebuch", 
 "UUID ya en uso": "UUID wird bereits verwendet", 
 "VPN creada correctamente": "VPN erfolgreich erstellt", 
 "VPN actualizada correctamente": "VPN erfolgreich aktualisiert", 
 "Contrase\u00f1a copiada al portapapeles": "Passwort in die Zwischenablage kopiert", 
 "Fallo al copiar la contrase\u00f1a": "Das Passwort konnte nicht kopiert werden", 
 "Nueva conexi\u00f3n VPN": "Neue VPN-Verbindung", 
 "Regenerar contrase\u00f1a": "Passwort neu generieren", 
 "Contrase\u00f1a VPN": "VPN-Passwort", 
 "Copie la contrase\u00f1a antes de guardar, no volver\u00e1 a ser visible": "Kopieren Sie das Passwort vor dem Speichern, es ist dann nicht mehr sichtbar", 
 "VPN (antigua)": "VPN (alt)", 
 "Ver acceso a VPN (antigua)": "VPN-Zugang anzeigen (alt)", 
 "Ver VPN (antigua)": "VPN anzeigen (alt)", 
 "Ver VPN": "VPN anzeigen", 
 "Crear VPN": "VPN erstellen", 
 "Editar VPN": "VPN bearbeiten", 
 "Ver informaci\u00f3n": "Siehe Informationen", 
 "Informaci\u00f3n placas": "Platteninformationen", 
 "Modelo de hardware": "Hardwaremodell", 
 "Fecha de compilaci\u00f3n": "Baudatum", 
 "Versi\u00f3n de Daemon": "Daemon-Version", 
 "Fecha de actualizaci\u00f3n": "Aktualisierungsdatum", 
 "Actualizado": "Aktualisiert", 
 "No actualizado": "Nicht aktualisiert", 
 "Descargar fichero de configuraci\u00f3n": "Konfigurationsdatei herunterladen", 
 "Nunca": "Niemals", 
 "Dispositivo": "Ger\u00e4t", 
 },
  "plural": "(n != 1)"
  };
  })(this);