(function (exports) { 
   exports.IT_IT_KICORE = {
 "catalog": { 
"Error 404": "Errore 404", 
 "Detalles Administrativos": "Dettagli amministrativi", 
 "Detalles  M2M": "Dettagli M2M", 
 "Search": "Ricerca", 
 "Reset": "Ripristina", 
 "Instalaci\u00f3n/Pedido": "Installazione/ordine", 
 "Cliente": "Cliente", 
 "Estado": "Estato", 
 "Desactivada": "Disabilitato", 
 "Activada": "Attivato", 
 "Inactiva nueva": "Nuovo inattivo", 
 "Lista para activaci\u00f3n": "Elenco per l'attivazione", 
 "Desconocido": "Uno sconosciuto", 
 "Consumo diario": "Consumo giornaliero", 
 "Consumo mensual": "Consumo mensile", 
 "Comienzo \u00faltima conexi\u00f3n": "Inizia l'ultima connessione", 
 "Fin \u00faltima conexi\u00f3n": "End Ultimate Connection", 
 "Compa\u00f1\u00eda de red": "Azienda di rete", 
 "C\u00f3digo de pa\u00eds": "Prefisso internazionale", 
 "Tipo de red": "Tipo di rete", 
 "Tipo de tarifa": "Tipo di tasso", 
 "Zona 1": "Zona 1", 
 "Sin asignar": "Non assegnato", 
 "Cambiar ciclo vida": "Cambiare il ciclo di vita", 
 "Por favor, seleccione una opci\u00f3n": "Per favore selezionate un'opzione", 
 "Desactivar": "Disattivare", 
 "Activar": "Attivare", 
 "Test": "Test", 
 "Estado GSM": "Stato GSM", 
 "Estado GPRS": "Stato GPRS", 
 "Buscar": "Cercare", 
 "Borrar": "Eliminare", 
 "Nombre": "Nome", 
 "Descripci\u00f3n": "Descrizione", 
 "UUID placa": "Distintivo UUID", 
 "Estado instalaci\u00f3n": "Stato dell'installazione", 
 "Desconectada": "Disconnesso", 
 "Conectada": "Collegato", 
 "Deshabilitada": "Disabilitato", 
 "Habilitada": "Abilitato", 
 "Detalles": "Dettagli", 
 "No se pudieron actualizar los datos": "Impossibile aggiornare i dati", 
 "Pools actualizados correctamente": "Pool aggiornati correttamente", 
 "Tarjetas activas / tarjetas totales": "Carte attive / carte totali", 
 "Consumo del pool / Pool total": "Consumo piscina / Totale piscina", 
 "Gasto estimado del pool": "Spesa stimata per la piscina", 
 "\u00daltima actualizaci\u00f3n": "Ultimo aggiornamento", 
 "Refrescar": "Ricaricare", 
 "Actualizar token de acceso": "Aggiornare il token di accesso", 
 "Por favor, complete este campo": "Si prega di completare questo campo", 
 "Enviar": "Inviare", 
 "Placa creada correctamente": "piastra creata con successo", 
 "Placa actualizada correctamente": "Targa aggiornata con successo", 
 "MAC err\u00f3nea": "MAC errato", 
 "SSID err\u00f3neo": "SSID errato", 
 "Nuevo kiwi": "Nuovo kiwi", 
 "Informaci\u00f3n de kiwi": "Informazioni sui kiwi", 
 "Guardar": "Mantenere", 
 "UUID err\u00f3neo": "UUID errato", 
 "Nuevo kibox": "Nuovo kibox", 
 "Informaci\u00f3n de kibox con UUID": "Informazioni Kibox con UUID", 
 "Informaci\u00f3n de kibox": "Informazioni Kibox", 
 "Habr\u00e1 mas de una placa con ese uuid.\u00bfEst\u00e1 seguro de esto?": "Ci sar\u00e0 pi\u00f9 di una piastra con quell'Uuid. Ne sei sicuro?", 
 "Modelo placa": "Modello a piastra", 
 "Seleccionar opci\u00f3n": "Selezionare l'opzione", 
 "Tarda demasiado en encenderse": "Ci vuole troppo tempo per accendersi", 
 "Usuario": "Utente", 
 "Nombre de usuario": "Nome utente", 
 "Rol": "Ruolo", 
 "Organizaci\u00f3n": "Organizzazione", 
 "Apellidos": "Cognomi", 
 "Email": "E-mail", 
 "Complete los campos obligatorios": "Completa i campi richiesti", 
 "Las contrase\u00f1as tienen que ser iguales": "Le password devono essere le stesse", 
 "Nombre de Usuario": "Nome utente", 
 "Correo electr\u00f3nico": "E-mail", 
 "Contrase\u00f1a": "Parola d'ordine", 
 "Repetir contrase\u00f1a": "Ripeti la password", 
 "Selecciona organizaci\u00f3n": "Seleziona l'organizzazione", 
 "Nivel": "Livello", 
 "Selecciona un rol": "Seleziona un ruolo", 
 "Superadministrador": "Super amministratore", 
 "Administrador": "Amministratore", 
 "Operador": "Operatore", 
 "Visitante": "Visitatore", 
 "Activo": "Risorsa", 
 "Crear cuenta": "Creare un account", 
 "Inicio": "Inizio", 
 "Nuevo equipo": "Nuova squadra", 
 "Stock": "Azione", 
 "Asignaciones": "Assegnazioni", 
 "Lista equipos": "Elenco delle squadre", 
 "Instalaciones": "Installazioni", 
 "Listado M2M": "Listato M2M", 
 "Listado Pools": "Listato Pools", 
 "Soporte": "Medio", 
 "Crear Usuario": "Creare un utente", 
 "Lista Usuarios": "Lista degli utenti", 
 "error": "errore", 
 "Informaci\u00f3n de tarjeta M2M con icc": "Informazioni sulla carta M2M con icc", 
 "Alias": "Alias", 
 "Life Cycle Status": "Stato del ciclo di vita", 
 "Pais": "Paese", 
 "Plan": "Piano", 
 "Estado Actual": "Stato attuale", 
 "\u00daltimo evento": "Ultimo evento", 
 "Tecno.Conexi\u00f3n": "Techno.Connection", 
 "\u00daltima Conexi\u00f3n": "Ultima connessione", 
 "Complete los campos": "Compila i campi", 
 "Inicio de sesi\u00f3n exitoso": "Accesso riuscito", 
 "Introduzca un nombre de usuario": "Inserisci un nome utente", 
 "Introduzca una contrase\u00f1a": "Inserisci una password", 
 "Iniciar sesi\u00f3n": "Login", 
 "Eliminar": "Eliminare", 
 "Editar": "Modificare", 
 "Asignar": "Assegnare", 
 "N\u00famero de serie err\u00f3neo": "Numero di serie errato", 
 "Nuevo KiWi": "Nuovo kiwi", 
 "Pedido": "Ordine", 
 "N serie": "N serie", 
 "Fecha fabricaci\u00f3n": "Data di fabbricazione", 
 "Estado de fabricaci\u00f3n": "Stato di produzione", 
 "KiWi terminado": "Kiwi finito", 
 "No se ha podido terminar": "Non poteva finire", 
 "Terminar": "Fine", 
 "Version hardware": "versione hardware", 
 "Modelo": "Modello", 
 "Tipo USB": "Tipo USB", 
 "Instalacion": "Facilit\u00e0", 
 "Cliente kiconex": "Cliente kiconex", 
 "Cliente final": "Cliente finale", 
 "N\u00famero de serie": "Numero di serie", 
 "kiconex plan": "Piano Kiconex", 
 "Nº dispositivos": "Nº dispositivi", 
 "Cuadro": "Grafico", 
 "Placa terminada": "Piatto finito", 
 "Opcionales": "Opzionale", 
 "Notas": "Notas", 
 "Crear nuevo dispositivo": "Creare un nuovo dispositivo", 
 "Bienvenido a kicore ¡la plataforma de gesti\u00f3n de Kiconex!": "Benvenuto in kicore, la piattaforma di gestione di Kiconex!", 
 "Ver en kiconex": "Vedi in Kiconex", 
 "ICC err\u00f3neo": "ICC sbagliato", 
 "Nombre instalaci\u00f3n": "Nome installazione", 
 "Nº serie": "Numero di serie", 
 "Versi\u00f3n hardware": "Versione hardware", 
 "Ninguno": "Nessuno", 
 "Chino": "Cinese", 
 "Basic": "Di Base", 
 "Professional": "Professionale", 
 "Premium": "Premio", 
 "Versi\u00f3n OEM": "Versione dell'OEM", 
 "No": "Ne pas", 
 "S\u00ed": "S\u00ec", 
 "Cable": "Filo", 
 "Fabricado": "Manufatto", 
 "Asignado": "Assegnato", 
 "Terminado": "Finito", 
 "Eliminado": "Rimosso", 
 "Volver": "Ritorno", 
 "Plan kiconex": "Piano Kiconex", 
 "¡MAC err\u00f3nea!": "MAC sbagliato!", 
 "¡SSID err\u00f3neo!": "SSID sbagliato!", 
 "¡UUID err\u00f3neo!": "UUID sbagliato!", 
 "¡ICC err\u00f3neo!": "ICC sbagliato!", 
 "¡N\u00famero de serie err\u00f3neo!": "Numero di serie errato!", 
 "Grupo comercial": "Gruppo commerciale", 
 "Habilitaci\u00f3n": "Qualificazione", 
 "F\u00e1brica": "Fabbrica", 
 "Administraci\u00f3n": "Amministrazione", 
 "Gesti\u00f3n": "Gestione", 
 "Usuarios": "Utenti", 
 "Lista kibox": "Elenco kibox", 
 "Lista kiwi": "Elenco kiwi", 
 "Kibox asignados": "Kibox assegnati", 
 "Kiwis asignados": "Kiwis assegnati", 
 "No se conecta al WiFi": "Non si connette al Wi-Fi", 
 "Este pedido debe salir con una pegatina extra": "Questo ordine deve essere accompagnato da un adesivo aggiuntivo", 
 "Cambiar estado de la SIM": "Cambiare lo stato della sim", 
 "Estado de la SIM": "Stato SIM", 
 "Fruter\u00eda fulanito": "Tal dei tali fruttivendolo", 
 "Token actualizado correctamente": "Token aggiornato con successo", 
 "Usuario creado correctamente": "Utente creato con successo", 
 "No hay respuesta del servidor": "Nessuna risposta dal server", 
 "Error": "Errore", 
 "Error al cerrar sesi\u00f3n": "Errore alla disconnessione", 
 "Seleccionar idioma": "Seleziona la lingua", 
 "Espa\u00f1ol": "Spagnolo", 
 "Ingl\u00e9s": "Inglese", 
 "Franc\u00e9s": "Francese", 
 "Alem\u00e1n": "Tedesco", 
 "Portugu\u00e9s": "Portoghese", 
 "Italiano": "Italiano", 
 "UUID": "UUID", 
 "Nombre comercial": "Nome depositato", 
 "Pedido comercial": "Ordine commerciale", 
 "N\u00famero de serie comercial": "Numero di serie commerciale", 
 "Modelo del equipo": "Modello di attrezzatura", 
 "Estado Comercial": "Stato commerciale", 
 "Fecha Conexi\u00f3n": "Data Connessione", 
 "Subscripci\u00f3n": "Sottoscrizione", 
 "Comercial": "Commerciale", 
 "General": "Generale", 
 "Placa borrada correctamente": "Lastra cancellata con successo", 
 "Sistema": "Sistema", 
 "Privilegios": "Privilegi", 
 "Roles": "Ruoli", 
 "Usuario borrado correctamente": "Utente eliminato con successo", 
 "Cambiar contrase\u00f1a": "Cambiare la password", 
 "Columnas": "Colonne", 
 "Sin informaci\u00f3n": "Nessuna informazione", 
 "Cerrar": "Vicino", 
 "Cambiar": "Modifica", 
 "Por favor, seleccione una opcion": "Per favore selezionate un'opzione", 
 "¡Las contrase\u00f1as deben coincidir!": "¡Le password devono essere identiche!", 
 "La contrase\u00f1a es un campo obligatorio": "La password \u00e8 un campo obbligatorio", 
 "success": "successo", 
 "Contrase\u00f1a actualizada correctamente": "Password aggiornata con successo", 
 "Las contrase\u00f1as deben coincidir": "Le password devono essere identiche", 
 "Cambio de contrase\u00f1a usuario": "Cambio password utente", 
 "Debe seleccionar un rol": "Devi selezionare un ruolo", 
 "Rol creado correctamente": "Ruolo creato correttamente", 
 "Nuevo Rol": "Nuovo ruolo", 
 "Informaci\u00f3n de Rol": "Informazioni sul ruolo", 
 "Rol borrado correctamente": "Ruolo eliminato correttamente", 
 "Rol administrador": "Ruolo di amministratore", 
 "Sincronizar": "Sincronizzare", 
 "¡Sincronizar datos!": "¡Sincronizzare i dati!", 
 "Nombre, Organizaci\u00f3n y subscripci\u00f3n van a ser modificados por el valor que tengan en la plataforma Kiconex \u00bfEst\u00e1 seguro de esto?": "Nome, Organizzazione e abbonamento verranno modificati dal valore che hanno nella piattaforma Kiconex \u00bfSei sicuro di questo?", 
 "Usuario guardado correctamente": "Utente salvato con successo", 
 "Nuevo usuario": "Nuovo utente", 
 "Editando usuario": "Modifica utente", 
 "Software": "Software", 
 "Crear": "Creare", 
 "Ver": "Vedere", 
 "Finalizar": "Fine", 
 "Editar comercial": "Modifica commerciale", 
 "Gesti\u00f3n de placas de f\u00e1brica": "Gestione targhe di fabbrica", 
 "Asignaciones de f\u00e1brica": "Incarichi di fabbrica", 
 "Asignaci\u00f3n de placas de f\u00e1brica": "Assegnazione delle targhe di fabbrica", 
 "Gesti\u00f3n de placas de administraci\u00f3n": "Gestione del consiglio di amministrazione", 
 "mykiconex": "mykiconex", 
 "Gesti\u00f3n con plataforma mykiconex": "Gestione con piattaforma mykiconex", 
 "M2M": "M2M", 
 "Ver M2M": "Vedi M2M", 
 "Ver estado": "Visualizzare lo stato", 
 "Ver listado pools": "Vedi elenco pools", 
 "Refrescar pools": "Ricaricare pools", 
 "Actualizar token": "Token di aggiornamento", 
 "VPN": "VPN", 
 "Chat": "Chat", 
 "Ver privilegios": "Vedi privilegi", 
 "Editar privilegios": "Modificare i privilegi", 
 "Gesti\u00f3n de sistema": "Gestione del sistema", 
 "Gesti\u00f3n de usuarios": "Gestione utenti", 
 "Ver acceso a chat": "Visualizza l'accesso alla chat", 
 "Ver acceso a soporte": "Vedi l'accesso al supporto", 
 "Ver acceso a VPN": "Vedere Accesso VPN", 
 "Gesti\u00f3n de tarjetas M2M": "Gestione delle carte M2M", 
 "Autorizaci\u00f3n": "Autorizzazione", 
 "Fecha primera contrataci\u00f3n": "Prima data di assunzione", 
 "Fecha \u00faltima renovaci\u00f3n": "Ultima data di rinnovamento", 
 "Intervalo de renovaci\u00f3n (a\u00f1os)": "Intervallo di rinnovo (anni)", 
 "Fecha primera conexi\u00f3n": "Prima data di connessione", 
 "Privilegios guardados correctamente": "I privilegi salvati correttamente", 
 "Rol actualizado correctamente": "Ruolo aggiornato correttamente", 
 "N\u00famero Pedido": "Numero d'ordine", 
 "Asunto": "Affare", 
 "Asignado a": "Assegnato a", 
 "Fecha de Creaci\u00f3n": "Data di creazione", 
 "Pendiente": "In sospeso", 
 "Cambiar Estado": "Cambiare stato", 
 "Estado del Pedido actualizado correctamente": "Ordina stato aggiornato correttamente", 
 "Cliente Final": "Cliente finale", 
 "Referencia Presupuesto": "Riferimento di bilancio", 
 "Precio Total": "Prezzo totale", 
 "Ver en CRM": "Vedi in CRM", 
 "N\u00famero Presupuesto": "Numero di bilancio", 
 "En Proceso": "In corso", 
 "Anulado": "Annullato", 
 "Cambiar estado del pedido": "Cambia lo stato dell'ordine", 
 "Pedidos": "Ordini", 
 "Gesti\u00f3n de pedidos": "Gestione degli ordini", 
 "Error en la operaci\u00f3n": "Errore operativo", 
 "UUID de placa ya en uso": "Placca uuid gi\u00e0 in uso", 
 "Nueva Placa Guardada": "Nuova piastra salvata", 
 "Placa actualizada": "Piastra aggiornata", 
 "Placa borrada": "Piastra cancellata", 
 "Placas actualizadas": "Piatti aggiornati", 
 "SSID de kiwi ya en uso": "Kiwi ssid gi\u00e0 in uso", 
 "Nuevo KiWi Guardado": "Nuovo kiwi salvato", 
 "KiWi actualizado": "Kiwi aggiornato", 
 "KiWi borrado": "Kiwi cancellato", 
 "Token actualizado": "Token aggiornato", 
 "Nombre de rol ya en uso": "Nome del ruolo gi\u00e0 in uso", 
 "Rol actualizado": "Ruolo aggiornato", 
 "No puede eliminar un rol con dependencias": "Non pu\u00f2 eliminare un ruolo con le dipendenze", 
 "La clave ha expirado.": "La chiave \u00e8 scaduta.", 
 "Usuario no encontrado.": "Utente non trovato.", 
 "Las contrase\u00f1as son obligatorias.": "Le passwords sono obbligatorie.", 
 "Las contrase\u00f1as no coinciden.": "Le passwords non corrispondono.", 
 "Nombre de usuario ya en uso": "User name gi\u00e0 usato", 
 "Error del servidor.": "Errore del server.", 
 "El usuario no ha sido activado.": "L'utente non \u00e8 stato attivato.", 
 "Inicio de sesi\u00f3n con \u00e9xito": "Accesso riuscito", 
 "Contrase\u00f1a incorrecta": "Password errata", 
 "Fecha Renovaci\u00f3n": "Data di rinnovo", 
 "Tiempo Restante": "Tempo Rimasto", 
 " meses restantes": " mesi rimanenti", 
 "Pedidos a expirar": "Ordini di scadere", 
 "Descargar Informe": "Scarica il rapporto", 
 "Renovaciones": "Ristrutturazioni", 
 "Pr\u00f3ximo mes": "Il prossimo mese", 
 "Pr\u00f3ximos 3 meses": "I prossimi 3 mesi", 
 "Pr\u00f3ximos 6 meses": "I prossimi 6 mesi", 
 "Pr\u00f3ximo a\u00f1o": "L'anno prossimo", 
 "Ver renovaciones": "Vedi i lavori di ristrutturazione", 
 "Caducada": "Scaduto", 
 " d\u00edas restantes": " giorni rimanenti", 
 "Aceptar": "Accettare", 
 "Reiniciar": "Ricomincia", 
 "Sin organizaci\u00f3n": "Nessuna organizzazione", 
 "Ver pools": "Vedi pools", 
 "Alarmas": "Allarmi", 
 "Alarmas M2M": "Allarmi M2M", 
 "Sin alertas": "Senza avvisi", 
 "Advertencia": "Avvertimento", 
 "Critica": "Critica", 
 "Renovaciones expiradas": "Ristrutturazioni scadute", 
 "Pedidos pendientes": "Ordini in attesa", 
 "Tarjetas desactivadas desde hace mas de 12 meses": "Carte disattivate per pi\u00f9 di 12 mesi", 
 "Ver m\u00e1s": "Vedi altro", 
 "Creaci\u00f3n": "Creazione", 
 "Actualizaci\u00f3n": "Aggiornamento", 
 "Borrado": "Cancellato", 
 "Cambio de estado": "Cambiamento di stato", 
 "Cambio de contrase\u00f1a": "Cambio di password", 
 "Sincronizaci\u00f3n": "Sincronizzazione", 
 "Sin acci\u00f3n": "Nessuna azione", 
 "Fecha": "Data", 
 "Acci\u00f3n": "Azione", 
 "Tipo de objeto": "Tipo di oggetto", 
 "Identificador": "Identificatore", 
 "Placa": "Piatto", 
 "Hace 3 horas": "3 ore fa", 
 "Hace 1 d\u00eda": "1 giorno fa", 
 "Hace 3 d\u00edas": "3 giorni fa", 
 "Hace 1 semana": "1 settimana fa", 
 "Actividad": "Attivit\u00e0", 
 "Listado": "Elenco", 
 "Ver actividad": "Vedere l'attivit\u00e0", 
 "Privilegio": "Privilegio", 
 "No es posible personificar desde un personificado.": "Non \u00e8 possibile personificare da un personificato.", 
 "Personificaci\u00f3n": "Personificazione", 
 "Personificando al usuario": "Personificando l'utente", 
 "Volver a la sesi\u00f3n": "Vai alla sessione", 
 "Personificar": "Personificare", 
 "No es posible personificarse a si mismo.": "Non \u00e8 possibile personificare te stesso.", 
 "Comienzo Personificaci\u00f3n": "Inizia la persona", 
 "Fin Personificaci\u00f3n": "Fine personificazione", 
 "Ajustes": "Impostazioni", 
 "Actualizar listado": "Elenco degli aggiornamenti", 
 "Refrescar pedidos": "Aggiorna gli ordini", 
 "Refrescar ordenes de trabajo": "Aggiorna gli ordini di lavoro", 
 "de": "di", 
 "filtrados de": "filtrato da", 
 "Actualizar listado de organizaciones": "Elenco degli aggiornamenti di organizzazioni", 
 "Actualizar nombre, plan y organizaci\u00f3n de kibox": "Aggiorna il nome, il piano e l'organizzazione di Kibox", 
 "Actualizar listado de pedidos": "Elenco degli ordini di aggiornamento", 
 "Actualizar listado de ordenes de trabajo": "Elenco degli ordini di lavoro di aggiornamento", 
 "Actualizaci\u00f3n de listados": "Aggiornamento dell'elenco", 
 "Actualizado con exito": "Aggiornato con successo", 
 "Activa": "Attivo", 
 "Sin intervalo": "Nessun intervallo", 
 "Mostrar todo": "Mostra tutto", 
 "Estado de suscripci\u00f3n no compatible con el intervalo seleccionado": "Stato di abbonamento non compatibile con l'intervallo selezionato", 
 "Estado del plan": "Stato del piano", 
 "Caducado": "Fuori tempo", 
 "Inactivo": "Oziare", 
 "Cancelado": "Annullato", 
 "No procede": "Non procede", 
 "¡Orden de trabajo erronea!": "Ordine di lavoro errato!", 
 "Rellene el formulario correctamente": "Compilare correttamente il modulo", 
 "Orden de trabajo": "Ordine di lavoro", 
 "Su sesi\u00f3n va a ser cerrada": "La tua sessione sar\u00e0 chiusa", 
 "Su sesi\u00f3n se cerrar\u00e1 para aplicar los cambios": "Verrai disconnesso per applicare le modifiche", 
 "Inicie sesi\u00f3n de nuevo": "Accedi di nuovo", 
 "Posee una versi\u00f3n no compatible": "Ha una versione non compatibile", 
 "Consumo": "Consumo", 
 "Consumo de la SIM": "Consumo della SIM", 
 "Mensual": "Mensile", 
 "Diario": "Diario", 
 "UUID ya en uso": "UUID gi\u00e0 in uso", 
 "VPN creada correctamente": "VPN creata con successo", 
 "VPN actualizada correctamente": "VPN aggiornata con successo", 
 "Contrase\u00f1a copiada al portapapeles": "Password copiata negli appunti", 
 "Fallo al copiar la contrase\u00f1a": "Impossibile copiare la password", 
 "Nueva conexi\u00f3n VPN": "Nuova connessione VPN", 
 "Regenerar contrase\u00f1a": "Rigenera la password", 
 "Contrase\u00f1a VPN": "Password VPN", 
 "Copie la contrase\u00f1a antes de guardar, no volver\u00e1 a ser visible": "Copia la password prima di salvare, non sar\u00e0 pi\u00f9 visibile", 
 "VPN (antigua)": "VPN (vecchio)", 
 "Ver acceso a VPN (antigua)": "Visualizza l'accesso VPN (vecchio)", 
 "Ver VPN (antigua)": "Visualizza VPN (vecchio)", 
 "Ver VPN": "Visualizza VPN", 
 "Crear VPN": "Crea VPN", 
 "Editar VPN": "Modifica VPN", 
 "Ver informaci\u00f3n": "Vedi informazioni", 
 "Informaci\u00f3n placas": "Informazioni sulla targa", 
 "Modelo de hardware": "Modello hardware", 
 "Fecha de compilaci\u00f3n": "Data di costruzione", 
 "Versi\u00f3n de Daemon": "Versione demone", 
 "Fecha de actualizaci\u00f3n": "Data di aggiornamento", 
 "Actualizado": "Aggiornato", 
 "No actualizado": "Non aggiornato", 
 "Descargar fichero de configuraci\u00f3n": "Scarica il file di configurazione", 
 "Nunca": "Mai", 
 "Dispositivo": "Dispositivo", 
 },
  "plural": "(n != 1)"
  };
  })(this);