(function (exports) { 
   exports.EN_US_KICORE = {
 "catalog": { 
"Error 404": "404 Error", 
 "Detalles Administrativos": "Administrative Details", 
 "Detalles  M2M": "M2M Details", 
 "Search": "Search", 
 "Reset": "Reset", 
 "Instalaci\u00f3n/Pedido": "Device/Order", 
 "Cliente": "Client", 
 "Estado": "Status", 
 "Desactivada": "Disabled", 
 "Activada": "Activated", 
 "Inactiva nueva": "Inactive new", 
 "Lista para activaci\u00f3n": "Ready for activation", 
 "Desconocido": "Unknown", 
 "Consumo diario": "Daily consumption", 
 "Consumo mensual": "Monthly consumption", 
 "Comienzo \u00faltima conexi\u00f3n": "Start of last connection", 
 "Fin \u00faltima conexi\u00f3n": "End of last connection", 
 "Compa\u00f1\u00eda de red": "Network company", 
 "C\u00f3digo de pa\u00eds": "Country code", 
 "Tipo de red": "Network type", 
 "Tipo de tarifa": "Fee type", 
 "Zona 1": "Zone 1", 
 "Sin asignar": "Not assigned", 
 "Cambiar ciclo vida": "Change lifecycle", 
 "Por favor, seleccione una opci\u00f3n": "Please select an option", 
 "Desactivar": "Deactivate", 
 "Activar": "Activate", 
 "Test": "Test", 
 "Estado GSM": "GSM Status", 
 "Estado GPRS": "GPRS Status", 
 "Buscar": "Search", 
 "Borrar": "Delete", 
 "Nombre": "Name", 
 "Descripci\u00f3n": "Description", 
 "UUID placa": "UUID Board", 
 "Estado instalaci\u00f3n": "Device status", 
 "Desconectada": "Disconnected", 
 "Conectada": "Connected", 
 "Deshabilitada": "Disabled", 
 "Habilitada": "Enabled", 
 "Detalles": "Details", 
 "No se pudieron actualizar los datos": "Failed to update data", 
 "Pools actualizados correctamente": "Pools updated successfully", 
 "Tarjetas activas / tarjetas totales": "Active Cards / Total Cards", 
 "Consumo del pool / Pool total": "Pool consumption / Total pool", 
 "Gasto estimado del pool": "Estimated Pool Expense", 
 "\u00daltima actualizaci\u00f3n": "Last update", 
 "Refrescar": "Refresh", 
 "Actualizar token de acceso": "Refrescar access token", 
 "Por favor, complete este campo": "Please, complete this field", 
 "Enviar": "Send", 
 "Placa creada correctamente": "Board created successfully", 
 "Placa actualizada correctamente": "Board updated successfully", 
 "MAC err\u00f3nea": "Wrong MAC", 
 "SSID err\u00f3neo": "Wrong SSID", 
 "Nuevo kiwi": "New kiwi", 
 "Informaci\u00f3n de kiwi": "Kiwi information", 
 "Guardar": "Save", 
 "UUID err\u00f3neo": "Wrong UUID", 
 "Nuevo kibox": "New kibox", 
 "Informaci\u00f3n de kibox con UUID": "Kibox information with UUID", 
 "Informaci\u00f3n de kibox": "Kibox information", 
 "Habr\u00e1 mas de una placa con ese uuid.\u00bfEst\u00e1 seguro de esto?": "There will be more than one board with that UUID. Are you sure of this?", 
 "Modelo placa": "Board model", 
 "Seleccionar opci\u00f3n": "Select an option", 
 "Tarda demasiado en encenderse": "Takes too long to turn on", 
 "Usuario": "User", 
 "Nombre de usuario": "Username", 
 "Rol": "Role", 
 "Organizaci\u00f3n": "Tenant", 
 "Apellidos": "Last name", 
 "Email": "Email", 
 "Complete los campos obligatorios": "Fill in the required fields", 
 "Las contrase\u00f1as tienen que ser iguales": "Passwords have to be the same", 
 "Nombre de Usuario": "Username", 
 "Correo electr\u00f3nico": "Email", 
 "Contrase\u00f1a": "Password", 
 "Repetir contrase\u00f1a": "Repeat password", 
 "Selecciona organizaci\u00f3n": "Select tenant", 
 "Nivel": "Level", 
 "Selecciona un rol": "Select a role", 
 "Superadministrador": "Superadmin", 
 "Administrador": "Admin", 
 "Operador": "Operator", 
 "Visitante": "Visitor", 
 "Activo": "Active", 
 "Crear cuenta": "Create account", 
 "Inicio": "Start", 
 "Nuevo equipo": "New device", 
 "Stock": "Stock", 
 "Asignaciones": "Assignments", 
 "Lista equipos": "Devices list", 
 "Instalaciones": "Devices", 
 "Listado M2M": "M2M List", 
 "Listado Pools": "Pools list", 
 "Soporte": "Support", 
 "Crear Usuario": "Create user", 
 "Lista Usuarios": "List users", 
 "error": "error", 
 "Informaci\u00f3n de tarjeta M2M con icc": "Information of M2M card with icc", 
 "Alias": "Alias", 
 "Life Cycle Status": "Life Cycle Status", 
 "Pais": "Country", 
 "Plan": "Subscription", 
 "Estado Actual": "Actual status", 
 "\u00daltimo evento": "Last event", 
 "Tecno.Conexi\u00f3n": "Conn.Tech.", 
 "\u00daltima Conexi\u00f3n": "Last connection", 
 "Complete los campos": "Complete the fields", 
 "Inicio de sesi\u00f3n exitoso": "Login successful", 
 "Introduzca un nombre de usuario": "Enter an username", 
 "Introduzca una contrase\u00f1a": "Enter a password", 
 "Iniciar sesi\u00f3n": "Login", 
 "Eliminar": "Delete", 
 "Editar": "Edit", 
 "Asignar": "Assign", 
 "N\u00famero de serie err\u00f3neo": "Wrong serial number", 
 "Nuevo KiWi": "New kiwi", 
 "Pedido": "Order", 
 "N serie": "Serial number", 
 "Fecha fabricaci\u00f3n": "Fabrication date", 
 "Estado de fabricaci\u00f3n": "Manufacturing status", 
 "KiWi terminado": "Kiwi finished", 
 "No se ha podido terminar": "Could not finish", 
 "Terminar": "Finish", 
 "Version hardware": "Hardware version", 
 "Modelo": "Model", 
 "Tipo USB": "USB type", 
 "Instalacion": "Device", 
 "Cliente kiconex": "Kiconex client", 
 "Cliente final": "Final client", 
 "N\u00famero de serie": "Serial number", 
 "kiconex plan": "Kiconex plan", 
 "Nº dispositivos": "Number of devices", 
 "Cuadro": "Frame", 
 "Placa terminada": "Finished board", 
 "Opcionales": "Optionals", 
 "Notas": "Notes", 
 "Crear nuevo dispositivo": "Create new device", 
 "Bienvenido a kicore ¡la plataforma de gesti\u00f3n de Kiconex!": "Welcome to kicore, the Kiconex management platform!", 
 "Ver en kiconex": "View in kiconex", 
 "ICC err\u00f3neo": "Wrong ICC", 
 "Nombre instalaci\u00f3n": "Device name", 
 "Nº serie": "Serial number", 
 "Versi\u00f3n hardware": "Hardware version", 
 "Ninguno": "None", 
 "Chino": "C", 
 "Basic": "Basic", 
 "Professional": "Professional", 
 "Premium": "Premium", 
 "Versi\u00f3n OEM": "OEM Version", 
 "No": "No", 
 "S\u00ed": "Yes", 
 "Cable": "Cable", 
 "Fabricado": "Manufactured", 
 "Asignado": "Assigned", 
 "Terminado": "Finished", 
 "Eliminado": "Deleted", 
 "Volver": "Return", 
 "Plan kiconex": "Kiconex forfait", 
 "¡MAC err\u00f3nea!": "Wrong MAC!", 
 "¡SSID err\u00f3neo!": "Wrong SSID!", 
 "¡UUID err\u00f3neo!": "Wrong UUID!", 
 "¡ICC err\u00f3neo!": "Wrong ICC!", 
 "¡N\u00famero de serie err\u00f3neo!": "Wrong serial number!", 
 "Grupo comercial": "Commercial group", 
 "Habilitaci\u00f3n": "Enablement", 
 "F\u00e1brica": "Factory", 
 "Administraci\u00f3n": "Administration", 
 "Gesti\u00f3n": "Management", 
 "Usuarios": "Users", 
 "Lista kibox": "Kibox list", 
 "Lista kiwi": "Kiwi list", 
 "Kibox asignados": "Assigned kibox", 
 "Kiwis asignados": "Assigned kiwis", 
 "No se conecta al WiFi": "Does not connect to WiFi", 
 "Este pedido debe salir con una pegatina extra": "This order must come out with an extra sticker", 
 "Cambiar estado de la SIM": "Change SIM status", 
 "Estado de la SIM": "SIM Status", 
 "Fruter\u00eda fulanito": "Example fruit store", 
 "Token actualizado correctamente": "Token updated successfully", 
 "Usuario creado correctamente": "User created successfully", 
 "No hay respuesta del servidor": "There is no response from the server", 
 "Error": "Error", 
 "Error al cerrar sesi\u00f3n": "Failed to log out", 
 "Seleccionar idioma": "Select Language", 
 "Espa\u00f1ol": "Spanish", 
 "Ingl\u00e9s": "English", 
 "Franc\u00e9s": "French", 
 "Alem\u00e1n": "Deutsch", 
 "UUID": "UUID", 
 "Nombre comercial": "Commercial name", 
 "Pedido comercial": "Commercial Order", 
 "N\u00famero de serie comercial": "Commercial serial number", 
 "Modelo del equipo": "Equipment model", 
 "Estado Comercial": "Commercial State", 
 "Fecha Conexi\u00f3n": "Date Connection", 
 "Subscripci\u00f3n": "Subscription", 
 "Comercial": "Commercial", 
 "General": "General", 
 "Placa borrada correctamente": "Board erased successfully", 
 "Sistema": "System", 
 "Privilegios": "Privileges", 
 "Roles": "Roles", 
 "Usuario borrado correctamente": "User deleted successfully", 
 "Cambiar contrase\u00f1a": "Change Password", 
 "Columnas": "Columns", 
 "Sin informaci\u00f3n": "No information", 
 "Cerrar": "Close", 
 "Cambiar": "Change", 
 "Por favor, seleccione una opcion": "Please select an option", 
 "¡Las contrase\u00f1as deben coincidir!": "¡Passwords must match!", 
 "La contrase\u00f1a es un campo obligatorio": "Password is a required field", 
 "success": "success", 
 "Contrase\u00f1a actualizada correctamente": "Password updated successfully", 
 "Las contrase\u00f1as deben coincidir": "Passwords must match", 
 "Cambio de contrase\u00f1a usuario": "User password change", 
 "Debe seleccionar un rol": "You must select a role", 
 "Rol creado correctamente": "Role created successfully", 
 "Nuevo Rol": "New Rol", 
 "Informaci\u00f3n de Rol": "Role Information", 
 "Rol borrado correctamente": "Role deleted successfully", 
 "Rol administrador": "Administrator role", 
 "Sincronizar": "Sync up", 
 "¡Sincronizar datos!": "¡Sync data!", 
 "Nombre, Organizaci\u00f3n y subscripci\u00f3n van a ser modificados por el valor que tengan en la plataforma Kiconex \u00bfEst\u00e1 seguro de esto?": "Name, Organization and subscription will be modified by the value they have in the Kiconex platform. Are you sure about this?", 
 "Usuario guardado correctamente": "User saved successfully", 
 "Nuevo usuario": "New user", 
 "Editando usuario": "Edit user", 
 "Software": "Software", 
 "Crear": "Create", 
 "Ver": "See", 
 "Finalizar": "Finish", 
 "Editar comercial": "Edit commercial", 
 "Gesti\u00f3n de placas de f\u00e1brica": "Factory license boards management", 
 "Asignaciones de f\u00e1brica": "Factory assignments", 
 "Asignaci\u00f3n de placas de f\u00e1brica": "Assignment of factory boards", 
 "Gesti\u00f3n de placas de administraci\u00f3n": "Administration Board Management", 
 "mykiconex": "mykiconex", 
 "Gesti\u00f3n con plataforma mykiconex": "Management with mykiconex platform", 
 "M2M": "M2M", 
 "Ver M2M": "See M2M", 
 "Ver estado": "View status", 
 "Ver listado pools": "See pool list", 
 "Refrescar pools": "Refrescar pools", 
 "Actualizar token": "Refrescar token", 
 "VPN": "VPN", 
 "Chat": "Chat", 
 "Ver privilegios": "View privileges", 
 "Editar privilegios": "Edit privileges", 
 "Gesti\u00f3n de sistema": "System management", 
 "Gesti\u00f3n de usuarios": "User Management", 
 "Ver acceso a chat": "View chat access", 
 "Ver acceso a soporte": "See access to support", 
 "Ver acceso a VPN": "See VPN access", 
 "Gesti\u00f3n de tarjetas M2M": "M2M Card management", 
 "Autorizaci\u00f3n": "Authorization", 
 "Portugu\u00e9s": "Portuguese", 
 "Italiano": "Italian", 
 "Fecha primera contrataci\u00f3n": "First hiring date", 
 "Fecha \u00faltima renovaci\u00f3n": "Last renewal date", 
 "Intervalo de renovaci\u00f3n (a\u00f1os)": "Renewal interval (years)", 
 "Fecha primera conexi\u00f3n": "First connection date", 
 "Privilegios guardados correctamente": "Privileges saved correctly", 
 "Rol actualizado correctamente": "Role updated correctly", 
 "N\u00famero Pedido": "Order number", 
 "Asunto": "Subject", 
 "Asignado a": "Assigned to", 
 "Fecha de Creaci\u00f3n": "Creation date", 
 "Pendiente": "Pending", 
 "Cambiar Estado": "Change Status", 
 "Estado del Pedido actualizado correctamente": "Order state updated correctly", 
 "Cliente Final": "Final client", 
 "Referencia Presupuesto": "Budget reference", 
 "Precio Total": "Total price", 
 "Ver en CRM": "See in CRM", 
 "N\u00famero Presupuesto": "Budget number", 
 "En Proceso": "In progress", 
 "Anulado": "Cancelled", 
 "Cambiar estado del pedido": "Change order status", 
 "Pedidos": "Orders", 
 "Gesti\u00f3n de pedidos": "Orders management", 
 "Error en la operaci\u00f3n": "Operation Error", 
 "UUID de placa ya en uso": "Board uuid already in use", 
 "Nueva Placa Guardada": "New saved board", 
 "Placa actualizada": "Updated board", 
 "Placa borrada": "Deleted board", 
 "Placas actualizadas": "Updated boards", 
 "SSID de kiwi ya en uso": "Kiwi ssid already in use", 
 "Nuevo KiWi Guardado": "New kiwi saved", 
 "KiWi actualizado": "Updated Kiwi", 
 "KiWi borrado": "Deleted Kiwi", 
 "Token actualizado": "Token updated", 
 "Nombre de rol ya en uso": "Role name already in use", 
 "Rol actualizado": "Updated role", 
 "No puede eliminar un rol con dependencias": "Cannot eliminate a role with dependencies", 
 "La clave ha expirado.": "The key has expired.", 
 "Usuario no encontrado.": "User not found.", 
 "Las contrase\u00f1as son obligatorias.": "Passwords are mandatory.", 
 "Las contrase\u00f1as no coinciden.": "Passwords do not match.", 
 "Nombre de usuario ya en uso": "User name already in use", 
 "Error del servidor.": "Server error.", 
 "El usuario no ha sido activado.": "The user has not been activated.", 
 "Inicio de sesi\u00f3n con \u00e9xito": "Login successful", 
 "Contrase\u00f1a incorrecta": "Incorrect password", 
 "Fecha Renovaci\u00f3n": "Renewal date", 
 "Tiempo Restante": "Time Left", 
 " meses restantes": " remaining months", 
 "Pedidos a expirar": "Orders to expire", 
 "Descargar Informe": "Download Report", 
 "Renovaciones": "Renovations", 
 "Pr\u00f3ximo mes": "Next month", 
 "Pr\u00f3ximos 3 meses": "Next 3 months", 
 "Pr\u00f3ximos 6 meses": "Next 6 months", 
 "Pr\u00f3ximo a\u00f1o": "Next year", 
 "Ver renovaciones": "See renovations", 
 "Caducada": "Expired", 
 " d\u00edas restantes": " remaining days", 
 "Aceptar": "Accept", 
 "Reiniciar": "Restart", 
 "Sin organizaci\u00f3n": "No organization", 
 "Ver pools": "See pools", 
 "Alarmas": "Alarms", 
 "Alarmas M2M": "Alarms M2M", 
 "Sin alertas": "Without alerts", 
 "Advertencia": "Warning", 
 "Critica": "Critical", 
 "Renovaciones expiradas": "Expired renovations", 
 "Pedidos pendientes": "Pending orders", 
 "Tarjetas desactivadas desde hace mas de 12 meses": "Cards deactivated for more than 12 months", 
 "Ver m\u00e1s": "See more", 
 "Creaci\u00f3n": "Creation", 
 "Actualizaci\u00f3n": "Update", 
 "Borrado": "Erased", 
 "Cambio de estado": "Status change", 
 "Cambio de contrase\u00f1a": "Change of password", 
 "Sincronizaci\u00f3n": "Synchronization", 
 "Sin acci\u00f3n": "No action", 
 "Fecha": "Date", 
 "Acci\u00f3n": "Action", 
 "Tipo de objeto": "Type of object", 
 "Identificador": "Identifier", 
 "Placa": "Board", 
 "Hace 3 horas": "3 hours ago", 
 "Hace 1 d\u00eda": "1 day ago", 
 "Hace 3 d\u00edas": "3 days ago", 
 "Hace 1 semana": "1 week ago", 
 "Actividad": "Activity", 
 "Listado": "List", 
 "Ver actividad": "View activity", 
 "Privilegio": "Privilege", 
 "No es posible personificar desde un personificado.": "It is not possible to personify from a personified.", 
 "Personificaci\u00f3n": "Personification", 
 "Personificando al usuario": "Personifying the user", 
 "Volver a la sesi\u00f3n": "Go to the session", 
 "Personificar": "Personify", 
 "No es posible personificarse a si mismo.": "It is not possible to personify yourself.", 
 "Comienzo Personificaci\u00f3n": "Start Person", 
 "Fin Personificaci\u00f3n": "End personification", 
 "Ajustes": "Settings", 
 "Actualizar listado": "Update list", 
 "Refrescar pedidos": "Refresh orders", 
 "Refrescar ordenes de trabajo": "Refresh work orders", 
 "de": "of", 
 "filtrados de": "filtered from", 
 "Actualizar listado de organizaciones": "Update List of Organizations", 
 "Actualizar nombre, plan y organizaci\u00f3n de kibox": "Update Kibox's name, plan and organization", 
 "Actualizar listado de pedidos": "Update orders list", 
 "Actualizar listado de ordenes de trabajo": "Update work orders list", 
 "Actualizaci\u00f3n de listados": "List update", 
 "Actualizado con exito": "Updated with success", 
 "Activa": "Active", 
 "Sin intervalo": "No interval", 
 "Mostrar todo": "Show everything", 
 "Estado de suscripci\u00f3n no compatible con el intervalo seleccionado": "Subscription status not compatible with the selected interval", 
 "Estado del plan": "Plan status", 
 "Caducado": "Timed out", 
 "Inactivo": "Idle", 
 "Cancelado": "Cancelled", 
 "No procede": "It does not proceed", 
 "¡Orden de trabajo erronea!": "Erroneous work order!", 
 "Rellene el formulario correctamente": "Fill the form correctly", 
 "Orden de trabajo": "Work order", 
 "Su sesi\u00f3n va a ser cerrada": "Your session will be closed", 
 "Su sesi\u00f3n se cerrar\u00e1 para aplicar los cambios": "You will be logged out to apply the changes", 
 "Inicie sesi\u00f3n de nuevo": "Sign in again", 
 "Posee una versi\u00f3n no compatible": "It has a non -compatible version", 
 "Consumo": "Consumption", 
 "Consumo de la SIM": "SIM consumption", 
 "Mensual": "Monthly", 
 "Diario": "Diary", 
 "UUID ya en uso": "UUID already in use", 
 "VPN creada correctamente": "Successfully created VPN", 
 "VPN actualizada correctamente": "Successfully updated VPN", 
 "Contrase\u00f1a copiada al portapapeles": "Password copied to clipboard", 
 "Fallo al copiar la contrase\u00f1a": "Failed to copy password", 
 "Nueva conexi\u00f3n VPN": "New VPN connection", 
 "Regenerar contrase\u00f1a": "Regenerate password", 
 "Contrase\u00f1a VPN": "VPN Password", 
 "Copie la contrase\u00f1a antes de guardar, no volver\u00e1 a ser visible": "Copy the password before saving, it will not be visible again", 
 "VPN (antigua)": "VPN (old)", 
 "Ver acceso a VPN (antigua)": "View VPN access (old)", 
 "Ver VPN (antigua)": "View VPN (old)", 
 "Ver VPN": "View VPN", 
 "Crear VPN": "Create VPN", 
 "Editar VPN": "Edit VPN", 
 "Ver informaci\u00f3n": "See information", 
 "Informaci\u00f3n placas": "Plate information", 
 "Modelo de hardware": "Hardware model", 
 "Fecha de compilaci\u00f3n": "Build date", 
 "Versi\u00f3n de Daemon": "Daemon version", 
 "Fecha de actualizaci\u00f3n": "Update date", 
 "Actualizado": "Updated", 
 "No actualizado": "Not updated", 
 "Descargar fichero de configuraci\u00f3n": "Download configuration file", 
 "Nunca": "Never", 
 "Dispositivo": "Device", 
 },
  "plural": "(n != 1)"
  };
  })(this);